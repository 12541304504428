<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true }" [template]="">
</ngx-loading>

<!-- NUEVO MENU -->
<!-- <div class="div-body"> -->
<section>
  <div id="sidemenu" [ngClass]="collapsed? 'menu-collapsed' : 'menu-expanded'">
    <!-- HEADER -->
    <div id="header">
      <div id="title"><span>Menú</span></div>
      <div id="menu-btn" (click)="toggle()">
        <i class="fa fa-bars" *ngIf="collapsed"></i>
        <i class="fa fa-times text-danger" *ngIf="!collapsed"></i>
      </div>
    </div>
    <!-- ITEMS -->
    <div id="menu-items">

      <!-- Home -->
      <div class="item tipo-cursor" (click)="goHome()">
        <a>
          <div class="icon">
            <div class="div-icn">
              <!-- <i class="fas fa-house-user"></i> -->
              <img src="../../../../assets/icons/menu/inicio.png" class="w-75" alt="">
            </div>
            <div class="div-title"><span>Inicio</span></div>
          </div>
        </a>
      </div>

      <div class="item separator"></div>

      <!-- Pedidos -->
      <div class="item tipo-cursor" (click)="selectMenu('pedidos')">
        <a>
          <div class="icon">
            <div class="div-icn">
              <!-- <i class="fas fa-clipboard-list"></i> -->
              <img src="../../../../assets/icons/menu/pedidos.png" class="w-75" alt="">
            </div>
            <div class="div-title"><span>Lista de Pedidos</span></div>
          </div>
        </a>
      </div>

      <!-- Datos Generales -->
      <div class="item tipo-cursor" (click)="selectMenu('datosGenerales')">
        <a>
          <div class="icon">
            <div class="div-icn">
              <!-- <i class="fa fa-address-book-o"></i> -->
              <img src="../../../../assets/icons/menu/datosgenerales.png" class="w-75" alt="">
            </div>
            <div class="div-title"><span>Datos Generales</span></div>
          </div>
        </a>
      </div>
      <div class="item tipo-cursor" (click)="selectMenu('servicios')">
        <a>
          <div class="icon">
            <div class="div-icn">
              <!-- <i class="fa fa-address-book-o"></i> -->
              <img src="../../../../assets/icons/menu/caja.png" class="w-75" alt="">
            </div>
            <div class="div-title"><span>Servicios Inicio</span></div>
          </div>
        </a>
      </div>

      <!-- Sobre nosotros -->
      <div class="item tipo-cursor" (click)="selectMenu('sobreNosotros')">
        <a>
          <div class="icon">
            <div class="div-icn">
              <!-- <i class="fas fa-info-circle"></i> -->
              <img src="../../../../assets/icons/menu/sobrenosotros.png" class="w-75" alt="">
            </div>
            <div class="div-title"><span>Sobre Misión-Visión</span></div>
          </div>
        </a>
      </div>

      <!-- Configuraciones -->
      <div class="item tipo-cursor" (click)="selectMenu('descuentos')">
        <a>
          <div class="icon">
            <div class="div-icn">
              <!-- <i class="fas fa-cogs"></i> -->
              <img src="../../../../assets/icons/menu/configuracion.png" class="w-75" alt="">
            </div>
            <div class="div-title"><span>Configuraciones</span></div>
          </div>
        </a>
      </div>

      <!-- Banner -->
      <div class="item tipo-cursor" (click)="selectMenu('imagenesBanner')">
        <a>
          <div class="icon">
            <div class="div-icn">
              <!-- <i class="far fa-images"></i> -->
              <img src="../../../../assets/icons/menu/banner.png" class="w-75" alt="">
            </div>
            <div class="div-title"><span>Imagenes Banner</span></div>
          </div>
        </a>
      </div>

      <!-- Grupos -->
      <div class="item tipo-cursor" (click)="selectMenu('imagenesGrupos')">
        <a>
          <div class="icon">
            <div class="div-icn">
              <!-- <i class="fab fa-buffer"></i> -->
              <img src="../../../../assets/icons/menu/categories.png" class="w-75" alt="">
            </div>
            <div class="div-title"><span>Administrar Grupos</span></div>
          </div>
        </a>
      </div>

      <!-- Productos -->
      <div class="item tipo-cursor" (click)="selectMenu('imagenesProductos')">
        <a>
          <div class="icon">
            <div class="div-icn">
              <!-- <i class="fas fa-gifts"></i> -->
              <img src="../../../../assets/icons/menu/product.png" class="w-75" alt="">
            </div>
            <div class="div-title"><span>Administrar Productos</span></div>
          </div>
        </a>
      </div>

      <!-- Vaucher -->
      <div class="item tipo-cursor" (click)="selectMenu('vaucher')" *ngIf="buttonsPay.datafast">
        <a>
          <div class="icon">
            <div class="div-icn">
              <img src="../../../../assets/icons/menu/payment.png" class="w-75" alt="">
            </div>
            <div class="div-title"><span>Generar Vaucher</span></div>
          </div>
        </a>
      </div>

      <!-- Colores Productos -->
      <div class="item tipo-cursor" (click)="selectMenu('coloresProductos')" *ngIf="configuracion.tipo_web == 2">
        <a>
          <div class="icon">
            <div class="div-icn">
              <!-- <i class="fa fa-paint-brush"></i> -->
              <img src="../../../../assets/icons/menu/color.png" class="w-75" alt="">
            </div>
            <div class="div-title"><span>Colores Productos</span></div>
          </div>
        </a>
      </div>

      <!-- Guia de tallas -->
      <div class="item tipo-cursor" (click)="selectMenu('guiaTallas')" *ngIf="configuracion.tipo_web == 2">
        <a>
          <div class="icon">
            <div class="div-icn">
              <!-- <i class="fa fa-crop"></i> -->
              <img src="../../../../assets/icons/menu/talla.png" class="w-75" alt="">
            </div>
            <div class="div-title"><span>Guía de tallas</span></div>
          </div>
        </a>
      </div>

      <!-- Promociones -->
      <div class="item tipo-cursor" (click)="selectMenu('promociones')">
        <a>
          <div class="icon">
            <div class="div-icn">
              <!-- <i class="fab fa-buffer"></i> -->
              <img src="../../../../assets/icons/menu/promocion.png" class="w-75" alt="">
            </div>
            <div class="div-title"><span>Promociones</span></div>
          </div>
        </a>
      </div>

      <!-- Catalogo -->
      <div class="item tipo-cursor" (click)="selectMenu('catalogue')">
        <a>
          <div class="icon">
            <div class="div-icn">
              <!-- <i class="fas fa-download text-success"></i> -->
              <img src="../../../../assets/icons/menu/catalogo.png" class="w-75" alt="">
            </div>
            <div class="div-title"><span>Generar Catálogo</span></div>
          </div>
        </a>
      </div>

      <!-- Pixel de Facebook -->
      <div class="item tipo-cursor" (click)="selectMenu('pixelFacebook')">
        <a>
          <div class="icon">
            <div class="div-icn">
              <!-- <i class="fab fa-facebook-square text-primary"></i> -->
              <img src="../../../../assets/icons/menu/facebook.png" class="w-75" alt="">
            </div>
            <div class="div-title"><span>Pixel Facebook</span></div>
          </div>
        </a>
      </div>

      <!-- Credenciales -->
      <div class="item tipo-cursor" (click)="selectMenu('credenciales')">
        <a>
          <div class="icon">
            <div class="div-icn">
              <!-- <i class="fas fa-key"></i> -->
              <img src="../../../../assets/icons/menu/llave.png" class="w-75" alt="">
            </div>
            <div class="div-title"><span>Credenciales</span></div>
          </div>
        </a>
      </div>

      <div class="item separator"></div>

      <!-- Cerrar Sesion -->
      <div class="item tipo-cursor" (click)="signOff()">
        <a>
          <div class="icon">
            <div class="div-icn">
              <!-- <i class="fas fa-lock text-danger"></i> -->
              <img src="../../../../assets/icons/menu/cerrar-sesion.png" class="w-75" alt="">
            </div>
            <div class="div-title text-danger"><span>Cerrar Sesión</span></div>
          </div>
        </a>
      </div>

    </div>
  </div>

  <div id="main-container" [ngClass]="collapsed? '': 'body-expanded'">

    <div class="row m-1 text-minuscula-form" *ngIf="menu.pedidos">

      <div class="col-lg-2 text-center mt-3">
        <div class="row border">
          <div class="col-lg-6 p-1">
            <button type="button" class="btn btn-dark btn-sm btn-block" (click)="listActualCotizations()">
              Actual
            </button>
          </div>

          <div class="col-lg-6 p-1">
            <button type="button" class="btn btn-success btn-sm btn-block" (click)="listAllCotizations()">
              Todos
            </button>
          </div>
        </div>
      </div>

      <div class="col-lg-7 text-center mt-3">
        <div class="row border ml-1 pt-1 pb-1">
          <div class="col-lg-5 text-center">
            <div class="row">
              <div class="col-lg-3">
                <label class="text-size-13" for="start">Desde</label>
              </div>
              <div class="col-lg-9 p-0">
                <input class="form-control form-control-sm" type="date" id="start" name="trip-start"
                  value="{{queryCortizations.desde}}" [(ngModel)]="queryCortizations.desde">
              </div>
            </div>
          </div>
          <div class="col-lg-5 text-center">
            <div class="row">
              <div class="col-lg-3">
                <label class="text-size-13" for="start">Hasta</label>
              </div>
              <div class="col-lg-9 p-0">
                <input class="form-control form-control-sm" type="date" id="end" name="trip-start"
                  value="{{queryCortizations.hasta}}" [(ngModel)]="queryCortizations.hasta">
              </div>
            </div>
          </div>
          <div class="col-lg-2 div-btn-list_cotizations">
            <button type="button" class="btn btn-warning btn-sm btn-block" (click)="listRangeDateCotizations()">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="col-lg-3 text-center mt-3">
        <div class="row border ml-1 pl-1 pt-1 pb-1">
          <div class="col-lg-8 p-0">
            <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name="" id=""
              placeholder="Cedula/Ruc/Pasaporte" [(ngModel)]="queryCortizations.cedula">
          </div>
          <div class="col-lg-4 div-btn-list_cotizations">
            <button type="button" class="btn btn-dark btn-sm btn-block" (click)="listCotizationsById()">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>

      <br>

      <div class="col-lg-12 mt-3 table-responsive" *ngIf="cotizations.length > 0">
        <table class="table table-hover text-size-13">
          <thead class="thead-dark">
            <tr class="text-center text-minuscula-form">
              <th scope="col">id</th>
              <th scope="col">Cliente</th>
              <th scope="col">Fecha/Hora Emisión</th>
              <th scope="col">Total</th>
              <th scope="col">Ver</th>
              <th scope="col">Comprobante</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let c of cotizations" class="text-size-11 text-minuscula-texto-negro text-center">
              <th>{{c.id}}</th>
              <th>{{c.nombres_cliente}} {{c.apellidos_cliente}} / {{c.cliente_cedulaRuc}}</th>
              <td>{{c.fechaCreacion}} / {{c.hora}}</td>
              <td>{{c.totalCompra | number : '1.2'}}</td>
              <td (click)="modalShowDetailCotization(showDetailCotizationModal ,c)">
                <i class="fas fa-eye text-size-14"></i>
              </td>
              <td  >
                <span *ngIf="c.tipo_pago_app==''">
                  --
                </span>
                <i *ngIf="c.tipo_pago_app!=''" (click)="verComprobante(c.tipo_pago_app)"  class="fa fa-file text-size-14 text-success" aria-hidden="true"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- <div class="col-lg-12 text-center" *ngIf="cotizations.length == 0">
        <img class="mt-5 w-50" src="https://drive.google.com/uc?export=view&id=1X1L4AvE7SN3O_gIucIkmsc47pMASDdS2">
      </div> -->

    </div>

    <div class="row m-1 text-minuscula-form  "  *ngIf="menu.datosGenerales">
      <div class="col-lg-12" >
        <div class="row m-0 rounded-5 "  >
          <div class="col-lg-6 text-center border p-2"  style="background-color: #f2f2f4; border-top-left-radius: 18px; border-bottom-left-radius: 18px;  ">
            <div class="row">
              <div class="col-6" (click)="ModalUploadFile(uploadFileModal, 'Logo', configuracion)">
                <img class="ml-3" [src]="urlBase+configuracion?.imgLogo+'?alt=media'"
                style="width: 150px">
              <i class="fas fa-edit ml-4" title="Editar Logo"></i>
              <br>
              <span class="text-danger tipo-letra text-size-11">LOGO 1 ( Tamaño recomendado 480 * 150 px)</span>
              </div>
              <div class="col-6" (click)="modalUploadLogo2(modaUploadLogo2)">
       

                <img *ngIf="information.logo_dos" class="ml-3" [src]="urlBase+information?.logo_dos+'?alt=media'"
                style="width: 150px">
              <i class="fas fa-edit ml-4" title="Editar Logo"></i>
              <br>
              <span class="text-danger tipo-letra text-size-11">LOGO 2 ( Tamaño recomendado 480 * 150 px)</span>
              </div>
            </div>
            <!-- <img class="ml-3" [src]="urlBase+configuracion?.imgLogo+'?alt=media'"
              style="width: 150px">
            <i class="fas fa-edit ml-4" title="Editar Logo"></i>
            <br>
            <span class="text-danger tipo-letra text-size-11">( Tamaño recomendado 480 * 150 px)</span> -->
          </div>



          <div class="col-lg-6  border  p-2" style="background-color: #f2f2f4; border-top-right-radius: 18px; border-bottom-right-radius: 18px;  " >
            <div class="row text-center">
              <div class="col-3 text-size-13 text-center">
                <label class="col-form-label">Color de fondo </label>
                <br>
                <input type="color" name="colorPrincipal" class="ml-3" [(ngModel)]="configuracion.colorPrincipal">
              </div>

              <div class="col-3 text-size-13 text-center">
                <label class="col-form-label">Texto Principal </label>
                <br>
                <input type="color" name="colorLetra" class="ml-3" [(ngModel)]="configuracion.colorLetra">
              </div>
              <div class="col-3 text-size-13 text-center">
                <label class="col-form-label">Texto Secundario</label>
                <br>
                <input type="color" name="colorLetra" class="ml-3" [(ngModel)]="configuracion.colorLetraSecundario">
              </div>

              <div class="col-3 text-center " style="margin-top: 1.65em;" >
                <button class="btn btn-dark btn-sm"
                  (click)="updateColors(configuracion.colorPrincipal, configuracion.colorLetra,configuracion.colorLetraSecundario)">
                  <i class="far fa-save"></i>
                </button>
              </div>

            </div>
          </div>

          <div class="col-lg-12 border rounded text-center text-size-13 mt-2 p-2 rounded-5 " style="background-color: #f2f2f4; "
            >
            <div class="row" >
              <div class="col-xxl-6 col-xl-5 col-lg-6" (click)="ModalUploadFile(uploadFileModal, 'Footer', configuracion)">
                <label class="col-form-label"> <strong class="text-size-13">Imagen de Portada</strong></label>
                <p class="text-danger text-size-12">El tamaño recomendado de la imagen debe ser de 1366 * 768 px</p>
                <img class="w-75 shadow-sm" [src]="urlBase+configuracion?.imgFooter+'?alt=media'"
                  *ngIf="configuracion?.imgFooter" style="width: 700px ; height: auto;">
                <!-- <img class="w-75" src="https://drive.google.com/uc?export=view&id={{configuracion?.imgFooter}}"
                  *ngIf="configuracion?.imgFooter"> -->
                <img class="w-75 shadow-lg " src="../../../../assets/img/footer2.jpg"
                  *ngIf="!configuracion?.imgFooter">
                <i class="fas fa-edit ml-4" title="Editar Logo"></i>
                <br>
              </div>
              <div class=" col-xxl-6 col-xl-7 col-lg-6">
                <form [formGroup]="formPortada" >

             
                  <div class="p-2" >
                    <div class="row border rounded-4 p-1   " style="background-color: white;">
                      <label class="col-form-label"> <strong class="text-size-13">Portada</strong></label>

                      <div class="col-xxl-9 col-xl-8 col-lg-8 ">
                        <div class="contenido3">
                          <label for="">Slogan</label>
                          <input type="text"  formControlName="slogan"  placeholder="Ingrese slogan empresa" class="form-control form-control-sm rounded-2 " >
                        </div>
                      </div>
                      <div class="col-xxl-3 col-xl-4 col-lg-4">
                        <div class="">
                          <label for="">Color</label>
                          <br>
                          <input type="color" formControlName="color" name="colorLetra" class="ml-3" >
                        </div>
                      </div>
                      <div class="col-xxl-12 mb-2">
                        <div class="contenido3">
                          <label for="">Detalle</label>
                          <textarea type="text" formControlName="detalle"  class="form-control form-control-sm rounded-2 " >
                          </textarea>
                        </div>
                      </div>
                    </div>
                    <label class="col-form-label"> <strong class="text-size-13">Sobre Nosotros</strong></label>

                    <div class="row border rounded-4 p-1   " style="background-color: white;">
                      <div class="col-xxl-6 col-12 ">
                        <div class="contenido3">
                          <label for="">Frase Clave</label>
                          <input type="text" formControlName="fraseClave"    placeholder="Ingrese frase clave" class="form-control form-control-sm rounded-2  " >
                        </div>
                      </div>
                      <div class="col-xxl-6 col-12 ">
                        <div class="contenido3">
                          <label for="">Frase Empresa</label>
                          <textarea type="text" formControlName="fraseEmpresa"   [maxlength]="300"    placeholder="Ingrese frase de la empresa" class="form-control form-control-sm rounded-2  " >
                          </textarea>
                          </div>
                      </div>
                      <div class="col-xxl-6 col-12 ">
                        <div class="contenido3 form-group text-size-14">
                          <label for="messenger">Video Promocional</label>
                          <input style="font-size: 13px;" class="form-control form-control-sm text-normal rounded-2" type="text" name="" id=""
                          formControlName="video" >
                        </div>
                      </div>
                    </div>

                    <div class="row border rounded-4 p-1  mt-3 " style="background-color: white;">
                      <div class="col-xxl-6 col-12 ">
                        <div class="contenido3">
                          <label for="">Descripción Empresa</label>
                          <textarea type="text"  formControlName="descEmpresa"  placeholder="Ingrese descripción de la empresa" class="form-control form-control-sm rounded-2  " >
                        </textarea>
                        </div>
                      </div>
                      <div class="col-xxl-6 col-12 ">
                        <div class="contenido3">
                          <label for="">Detalle Frase Empresa</label>
                          <textarea type="text"  [maxlength]="300"   formControlName="detalleDesEmpresa" placeholder="Ingrese detalle frase empresa" class="form-control form-control-sm rounded-2  " >
                          </textarea>
                          </div>
                      </div>
                    </div>   
                    <div class="row divSelect mt-3 ">
                        <div class="col-6">
                          <button class="btn btn-sm rounded-2 btn-dark"  (click)="updateDataPortada(information,formPortada.value )" >
                              Guardar  <i class="bi bi-floppy2-fill"></i>
                          </button>
                        </div>
                    </div> 
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>

        <div class="row border rounder mt-2 ml-0 mr-0 rounded-5"  style="background-color: #f2f2f4; ">
          <div class="col-lg-12 ">
            <div class="row   r">
              <div class="col">
                <div class=" contenido3 form-group text-size-14">
                  <label for="nombre">Nombre</label>
                  <input style="font-size: 13px;" class="form-control form-control-sm rounded-2 normal" type="text" name="" id=""
                    [(ngModel)]="information.nombre">
                </div>
              </div>
              <div class="col divSelect ">
                <label class="switch ">
                  <input  *ngIf="information.esPuntoVenta == 1"  type="checkbox" checked (change)="changeTipoNegocio($event,information)" >
                  <input   *ngIf="information.esPuntoVenta == 0" type="checkbox" (change)="changeTipoNegocio($event,information)" >
                  <span class="slider round"> </span>
                </label>
                <span *ngIf="information.esPuntoVenta == 0"  class="" style="padding-left: 0.5em;" >
                  No es punto de Venta
                </span>
                <span *ngIf="information.esPuntoVenta == 1" class=" fw-bold " style="padding-left: 0.5em;" >
                  Es punto de Venta
                </span>
              </div>
            </div>

            <div class="form-group text-size-14 border rounded-5 p-2 bg-white ">
              <div class=" divSelect " >
                <label class="col-form-label text-center "> <strong class="text-size-13">Cuenta Bancaria</strong></label>
              </div>
              <div class="row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-12 ">
                  <div class="contenido3">
                    <label for="">Número de cuenta</label>
                    <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name="" id=""
                    placeholder="Número de cuenta" [(ngModel)]="information.numeroCuenta">
                  </div>                
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-12 ">
                  <div class="contenido3">
                    <label for="">Entidad bancaria</label>
                    <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name="" id=""
                    placeholder="Entidad bancaria" [(ngModel)]="information.nombreBanco">
                  </div>
                </div>
                <div class="col-xxl-4 col-xl-6 col-lg-6 col-12 ">
                  <div class="contenido3">
                    <label for="">Titular de la cuenta</label>
                    <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name="" id=""
                    placeholder="Titular de la cuenta" [(ngModel)]="information.nombreCuenta">
                  </div>
                </div>
                <div class="col-xxl-4 col-xl-6 col-lg-6 col-12 ">
                  <div class="contenido3">
                    <label for="">CI/RUC de la cuenta</label>
                    <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name="" id=""
                    placeholder="CI/RUC de la cuenta" [(ngModel)]="information.ruc_ci_cuenta">
                  </div>
                </div>
                <div class="col-xxl-4 col-xl-6 col-lg-6 col-12 ">
                  <div class="contenido3">
                    <label for="">Tipo de cuenta</label>
                    <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name="" id=""
                    placeholder="Tipo de cuenta" [(ngModel)]="information.tipo_cuenta">
                  </div>
                </div>
              </div>


              <div class="form-group text-size-14 pt-3">
                <label for="nombre " class="font-weight " >QR DE PAGOS</label>
                <div class="row">
                  <div class="col-lg-6 col-md-5">
                    <div class="card rounded-4 " style="height: 280px;width:100% " id="cardQR">
                      <img src="../../../../assets/img/qrExample.png" alt="" style="width: 100%; height: 100%; object-fit:scale-down;" >
                    </div>

                  </div>
                  <div class="col-lg-6 col-md-7">
                    <h4 class="text-center" >Agrega tus QRs de PAGO</h4>
                    <small class="text-center mb-3 " style="display:flex; justify-content: center; " >(El tamaño recomendado de la imagen debe ser de : <strong> 300 * 400 px</strong> )</small>

                      <div class="col pb-2">  

                        <div class="file-input-container" *ngIf="!information.imgQrP" >
                          <input type="file" class="file-input" id="fileInput" (change)="onSelectQR1($event)">
                          <label for="fileInput" class="file-input-label"> 
                            <i class="fa fa-cloud-upload pr-2" aria-hidden="true" ></i>
                            Cargar imagen QR #1
                          </label>
                        </div>

                        <div class="text-center pb-2 d-block" *ngIf="information.imgQrP">
                          <div class="col-12 mb-2">
                            <span  for=""class="mb-2" >{{information.imgQrP}}</span>
                            <span  title="VER QR" class="bg-success px-1 py-0.5 mb-2 ml-1"  style="border-radius: 4px;" >
                              <i class="fa fa-eye text-white" aria-hidden="true" (click)="verComprobante(information.imgQrP)"></i>
                            </span>
                            <span  title="ELIMINAR QR"  class="bg-danger px-1 mb-2  ml-1"  style="border-radius: 4px;" >
                              <i class="fa fa-trash-o text-white" aria-hidden="true" (click)="onRemoveQR1(information.imgQrP)"></i>
                            </span>                               
                          </div>
                        </div> 
                     
                      </div>
                      <div class="col pb-2">

                        <div class="file-input-container" *ngIf="!information.imgQrS " >
                          <input type="file" class="file-input" id="fileInput" (change)="onSelectQR2($event)">
                          <label for="fileInput" class="file-input-label"> 
                            <i class="fa fa-cloud-upload pr-2" aria-hidden="true"></i>
                            Cargar imagen QR #2
                          </label>
                        </div>
                        <div class="text-center pb-2 d-block" *ngIf="information.imgQrS">
                          <div class="col-12 mb-2">
                            <span  for=""class="mb-2" >{{information.imgQrS}}</span>
                            <span  title="VER QR" class="bg-success px-1 py-0.5 mb-2 ml-1"  style="border-radius: 4px;" >
                              <i class="fa fa-eye text-white" aria-hidden="true" (click)="verComprobante(information.imgQrS)"></i>
                            </span>
                            <span  title="ELIMINAR QR"  class="bg-danger px-1 mb-2  ml-1"  style="border-radius: 4px;" >
                              <i class="fa fa-trash-o text-white" aria-hidden="true" (click)="onRemoveQR2(information.imgQrS)"></i>
                            </span>                               
                          </div>
                        </div> 
                      </div>
                      <div class="col pb-2">
                        <!-- <label for="">QR #3</label> -->

                        <div class="file-input-container" *ngIf="!information.imgQrT" >
                          <input type="file" class="file-input" id="fileInput" (change)="onSelectQR3($event)">
                          <label for="fileInput" class="file-input-label"> 
                            <i class="fa fa-cloud-upload pr-2" aria-hidden="true"></i>
                            Cargar imagen QR #3
                          </label>
                        </div>
                        <div class="text-center pb-2 d-block" *ngIf="information.imgQrT ">
                          <div class="col-12 mb-2">
                            <span  for=""class="mb-2" >{{information.imgQrT}}</span>
                            <span  title="VER QR" class="bg-success px-1 py-0.5 mb-2 ml-1"  style="border-radius: 4px;" >
                              <i class="fa fa-eye text-white" aria-hidden="true" (click)="verComprobante(information.imgQrT)"></i>
                            </span>
                            <span  title="ELIMINAR QR"  class="bg-danger px-1 mb-2  ml-1"  style="border-radius: 4px;" >
                              <i class="fa fa-trash-o text-white" aria-hidden="true" (click)="onRemoveQR3(information.imgQrT)"></i>
                            </span>                               
                          </div>
                        </div> 
                      </div>
                  </div>
                </div>
                <!-- <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name="" id=""
                  [(ngModel)]="information.nombre"> -->
              </div>

          
            </div>
          </div>
          <div class="col-lg-6">
            <div class=" contenido3 form-group text-size-14">
              <label for="direccion">Dirección</label>
              <input style="font-size: 13px;" class="form-control form-control-sm text-normal rounded-2" type="text" name="" id=""
                [(ngModel)]="information.direccion">
            </div>
          </div>
          <div class="col-lg-6 ">
            <div class=" contenido3 form-group text-size-14">
              <label for="email">Email</label>
              <input style="font-size: 13px;" class="form-control form-control-sm text-normal rounded-2" type="text" name="" id=""
                [(ngModel)]="information.email">
            </div>
          </div>
          <div class="col-lg-6">
            <div class=" contenido3 form-group text-size-14">
              <label for="telefonos">Teléfonos</label>
              <input style="font-size: 13px;" class="form-control form-control-sm text-normal rounded-2" type="text" name="" id=""
                [(ngModel)]="information.telefonos">
            </div>
          </div>
          <div class="col-lg-6 ">
            <div class="contenido3 form-group text-size-14">
              <label for="whatsapp">WhatsApp</label>
              <input style="font-size: 13px;" class="form-control form-control-sm text-normal rounded-2" type="text" name="" id=""
                placeholder="593912345678" [(ngModel)]="information.whatsapp">
            </div>
          </div>
          <div class=" col-lg-6 ">
            <div class="contenido3 form-group text-size-14">
              <label for="facebook">Facebook</label>
              <input style="font-size: 13px;" class="form-control form-control-sm text-normal rounded-2" type="text" name="" id=""
                [(ngModel)]="information.facebook">
            </div>
          </div>
          <div class="col-lg-6 ">
            <div class="contenido3 form-group text-size-14">
              <label for="instagram">Instagram</label>
              <input style="font-size: 13px;" class="form-control form-control-sm text-normal rounded-2" type="text" name="" id=""
                [(ngModel)]="information.instagram">
            </div>
          </div>
          <div class="col-lg-6 ">
            <div class="contenido3 form-group text-size-14">
              <label for="youtube">YouTube</label>
              <input style="font-size: 13px;" class="form-control form-control-sm text-normal rounded-2" type="text" name="" id=""
                placeholder="https://www.youtube.com" [(ngModel)]="information.youtube">
            </div>
          </div>
          <div class="col-lg-6 ">
            <div class="contenido3 form-group text-size-14">
              <label for="twitter">Twitter</label>
              <input style="font-size: 13px;" class="form-control form-control-sm text-normal rounded-2" type="text" name="" id=""
                [(ngModel)]="information.twitter">
            </div>
          </div>
          <div class="col-lg-6 ">
            <div class="contenido3 form-group text-size-14">
              <label for="tiktok">TikTok</label>
              <input style="font-size: 13px;" class="form-control form-control-sm text-normal rounded-2" type="text" name="" id=""
                [(ngModel)]="information.tiktok">
            </div>
          </div>
          <div class="col-lg-6 ">
            <div class="contenido3 form-group text-size-14">
              <label for="messenger">Messenger</label>
              <input style="font-size: 13px;" class="form-control form-control-sm text-normal rounded-2" type="text" name="" id=""
                [(ngModel)]="information.messenger">
            </div>
          </div>
          <!-- <div class="col-lg-6 ">
            <div class="contenido3 form-group text-size-14">
              <label for="messenger">Video Promocional</label>
              <input style="font-size: 13px;" class="form-control form-control-sm text-normal rounded-2" type="text" name="" id=""
                [(ngModel)]="information.video">
            </div>
          </div> -->
          <div class="col-lg-6 ">
            <div class="contenido3 form-group text-size-14">
              <label for="blog">Blog</label>
              <input style="font-size: 13px;" class="form-control form-control-sm text-normal rounded-2 "  type="text" name="" id=""
                [(ngModel)]="information.blog">
            </div>
          </div>
          <div class=" form-group text-center w-100">
            <button type="button" class="btn btn-dark btn-sm" (click)="updateInformation(information)">
              <i class="far fa-save"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-1 text-minuscula-form" *ngIf="menu.sobreNosotros">
      <!-- <div class="col-lg-12">
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-9 text-size-14 text-center mt-2 mb-2">
            <label for="historia">Historia</label>
            <div class="row">
              <div class="col-12">
                <textarea style="font-size: 13px;" class="form-control form-control-sm text-normal text-justify"
                  rows="4" [(ngModel)]="information.historia"></textarea>
              </div>
            </div>
          </div>
          <div class="col-lg-3 text-center">
            <img class="img-info mt-1 w-75" *ngIf="information.imagenHistoria"
             [src]="urlBase+information.imagenHistoria+'?alt=media'">
            <img class="img-info mt-1 w-75" *ngIf="!information.imagenHistoria"
             src="../../../../assets/img/imgDefault/historia.jpg">
            <i class="fas fa-edit ml-2" title="Editar Imagen sobre nosotros"
              (click)="ModalUploadFile(uploadFileModal, 'AboutUs', 'history')"></i>
            <br>
            <span class="text-danger text-size-11">(Tamaño 600 * 600 px)</span>
          </div>
        </div>
      </div> -->
      <div class="col-lg-12">
        <div class="row border rounded-4 mt-2 ml-0 mr-0 " style="background-color: #f2f2f4;" >
          <div class="col-lg-9 text-size-14 text-center mt-2 mb-2">
            <label for="mision">Misión</label>
            <div class="row">
              <div class="col-12">
                <textarea style="font-size: 13px;" class="form-control form-control-sm text-normal" rows="4"
                  [(ngModel)]="information.mision"></textarea>
              </div>
            </div>
          </div>
          <div class="col-lg-3 text-center">
            <!-- <img class="img-info mt-1 w-50" *ngIf="!information.imagenMision"
              src="../../../../assets/img/iconos-mision-01.png"> -->
              <img class="img-info mt-1 w-75" *ngIf="information.imagenMision"
              [src]="urlBase+information.imagenMision+'?alt=media'">
            <img class="img-info mt-1 w-75" *ngIf="!information.imagenMision"
            src="../../../../assets/img/iconos-mision-01.png">
            <i class="fas fa-edit ml-2" (click)="ModalUploadFile(uploadFileModal, 'AboutUs', 'mision')"></i>
            <br>
            <span class="text-danger text-size-11">(Tamaño 512 * 512 px)</span>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="row border rounded-4 mt-2 ml-0 mr-0 " style="background-color: #f2f2f4;" >
          <div class="col-lg-9 text-size-14 text-center mt-2 mb-2">
            <label for="vision">Visión</label>
            <div class="row">
              <div class="col-12">
                <textarea style="font-size: 13px;" class="form-control form-control-sm text-normal" rows="4"
                  [(ngModel)]="information.vision"></textarea>
              </div>
            </div>
          </div>
          <div class="col-lg-3 text-center">

            <img class="img-info mt-1 w-75" *ngIf="information.imagenVision"
              [src]="urlBase+information.imagenVision+'?alt=media'">
            <img class="img-info mt-1 w-75" *ngIf="!information.imagenVision"
            src="../../../../assets/img/iconos-vision-01.png">
            <i class="fas fa-edit ml-2" (click)="ModalUploadFile(uploadFileModal, 'AboutUs', 'vision')"></i>
            <br>
            <span class="text-danger text-size-11">(Tamaño 512 * 512 px)</span>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="row border rounded-4 mt-2 ml-0 mr-0 " style="background-color: #f2f2f4;" >
          <div class="col-lg-9 text-size-14 text-center mt-2 mb-2">
            <!-- <label for="valores">Valores</label> -->
            <label for="valores">Valores</label>
            <div class="row">
              <div class="col-12">
                <textarea style="font-size: 13px;" class="form-control form-control-sm text-normal" rows="4"
                  [(ngModel)]="information.valores"></textarea>
              </div>
            </div>
          </div>
          <div class="col-lg-3 text-center">
            <!-- <img class="img-info mt-1 w-75" *ngIf="information.imagenValores"
              src="https://drive.google.com/uc?export=view&id={{information.imagenMision}}">
            <img class="img-info mt-1 w-75" *ngIf="!information.imagenValores"
              src="https://drive.google.com/uc?export=view&id=1O6qHa3fef2bXutBz2TYuHTzyreCw7QcK">
            <i class="fas fa-edit ml-2" (click)="ModalUploadFile(uploadFileModal, 'AboutUs', 'valores')"></i>
            <br>
            <span class="text-danger text-size-11">(Tamaño 512 * 512 px)</span> -->
          </div>
        </div>
      </div>
      <div class="form-group text-center w-100 mt-3">
        <button type="button text-center " class="btn btn-dark btn-sm" (click)="updateAboutUs(information)">
          <i class="far fa-save"></i>
        </button>
      </div>
    </div>

    <div class="row m-1 text-minuscula-form" *ngIf="menu.descuentos">
      <div class="col-lg-12">
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-2 text-size-13 text-center mt-2 mb-2">
            <label>Mostrar precios</label>
            <br>
            <select class="w-100" (change)="updateShowPrice($event)" [(ngModel)]="configuracion.mostrar_precio"
              title="Mostrar/Ocultar precios de productos">
              <option value="1">Si</option>
              <option value="0">No</option>
            </select>
          </div>

          <div class="col-lg-3 text-size-13 text-center mt-2 mb-2">
            <label>Bodega</label>
            <br>
            <select class="w-100" (change)="updateBodega($event)" [(ngModel)]="configuracion.id_bodega"
              title="Establecer la bodega de la tienda.">
              <option value="0">Todas</option>
              <option value="{{b.id}}" *ngFor="let b of bodegas">{{b.nombre}}</option>
            </select>
          </div>

          <div class="col-lg-2 text-size-13 text-center mt-2 mb-2">
            <label>Tipo de precio</label>
            <br>
            <select class="w-100" (change)="updatePriceType($event)" [(ngModel)]="configuracion.tipoPrecio"
              title="Establecer el tipo de precio a mostrar del producto.">
              <option value="{{t.tipoprecio}}" *ngFor="let t of pricesTypes">{{t.tipoprecio}}
              </option>
            </select>
          </div>

          <div class="col-lg-3 text-size-13 text-center mt-2 mb-2">
            <label>Productos tendencia</label>
            <br>
            <select class="w-100" (change)="updateTrendProductsShow($event)"
              [(ngModel)]="configuracion.productos_tendencia" title="Establecer los productos por defecto a visualizar">
              <option value="1">Productos Nuevos</option>
              <option value="2">Productos Promoción</option>
              <option value="3">Grupos</option>
            </select>
          </div>

          <div class="col-lg-2 text-size-13 text-center mt-2 mb-2">
            <label>Página de inicio</label>
            <br>
            <select class="w-100" (change)="updateRouteDefault($event)" [(ngModel)]="configuracion.ruta_inicio_defecto"
              title="Establecer ruta de inicio del sitio">
              <option value="home/1">Home</option>
              <option value="products">Catálogo</option>
              <!-- <option value="about-us">Sobre Nosotros</option> -->
              <option value="Inicio">Inicio</option>
            </select>
          </div>
        </div>

        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2 border-bottom">
            <p class="text-center"><strong class="text-size-13 text-normal">Modificar Nombres del Menú</strong></p>
          </div>
          <div class="col-lg-12 text-size-13  mt-2 mb-2">
            <!-- <div class="form-group text-size-13">
             
              <label> {{menu_client[0].descripcion}} </label>
              <div class="input-group input-group-sm">
                <div class="input-group-prepend" style="width: 30%;">
                  <span class="input-group-text w-100" id="inputGroup-sizing-sm">Ejm. {{menu_client[0].nombre_referencia}}</span>
                </div>
                <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                  placeholder="Nombre del menú" [(ngModel)]="menu_client[0].nombre">
                <div class="input-group-append">
                  <button type="button" class="btn btn-outline-dark btn-sm" (click)="updateMenu(menu_client[0])"><i
                      class="fa fa-floppy-o"></i></button>
                </div>
              </div>
            </div> -->
            <div class="form-group text-size-13" *ngFor="let m of menu_client">
              <p>{{m |json}}</p>
              <label> {{m.descripcion}} </label>
              <div class="input-group input-group-sm">
                <div class="input-group-prepend" style="width: 30%;">
                  <span class="input-group-text w-100" id="inputGroup-sizing-sm">Ejm. {{m.nombre_referencia}}</span>
                </div>
                <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                  placeholder="Nombre del menú" [(ngModel)]="m.nombre">
                <div class="input-group-append">
                  <button type="button" class="btn btn-outline-dark btn-sm" (click)="updateMenu(m)"><i
                      class="fa fa-floppy-o"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2 border-bottom">
            <p class="text-center"><strong class="text-size-13 text-normal">Descuentos y Valores</strong></p>
          </div>
          <div class="col-lg-6 text-size-13 text-center mt-1">
            <div class="form-group text-size-13">
              <label> Porcentaje precio oferta Ejm: ( <span
                  class="text-secondary mr-2 text-subrayado-gray">${{price.before | number: '1.2'}} </span>
                <span> $ {{price.now | number: '1.2'}}</span>
                )
              </label>
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm">%</span>
                </div>
                <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                  placeholder="0" [(ngModel)]="configuracion.porcentajePrecioOferta">
              </div>
            </div>
          </div>
          <div class="col-lg-6 text-size-13 text-center mt-1">
            <div class="form-group text-size-13">
              <label>Porcentaje Descuento por promoción Ejm: (<strong>Black Friday</strong> )</label>
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm">%</span>
                </div>
                <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                  placeholder="0" [(ngModel)]="configuracion.porcentajeDescuento">
              </div>
            </div>
          </div>
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2 border-bottom">
            <p class="text-center"><strong class="text-size-13 text-normal">Tarjeta de crédito</strong></p>
          </div>
          <div class="col-lg-8 text-size-13 text-center mt-1">
            <div class="form-group text-size-13">
              <label>Descripción del recargo</label>
              <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name="" id=""
                [(ngModel)]="configuracion.txtRecargoTarjetaC">
            </div>
          </div>
          <div class="col-lg-4 text-size-13 text-center mt-1">
            <div class="form-group text-size-13">
              <label>Porcentaje del recargo</label>
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm">%</span>
                </div>
                <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                  placeholder="0" [(ngModel)]="configuracion.porcentajeCompraTarjeta">
              </div>
            </div>
          </div>
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2 border-bottom">
            <p class="text-center"><strong class="text-size-13 text-normal">Botones de Compras</strong></p>
          </div>
          <div class="col-lg-4 text-size-13 text-center mt-1 mb-2">
            <div class="border rounded p-2">
              <button type="button" class="btn-sm btn-block pt-2 pb-2 btn-recoger text-light">
                <span><strong class="text-size-12">{{configuracion.txtBtnEnviarPedido1}}</strong></span>
                <i class="fas fa-store ml-3"></i>
              </button>
              <br>
              <label>Descripción del Boton</label>
              <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name="" id=""
                [(ngModel)]="configuracion.txtBtnEnviarPedido1">
            </div>
          </div>
          <div class="col-lg-4 text-size-13 text-center mt-1 mb-2">
            <div class="border rounded p-2">
              <button type="button" class="btn-sm btn-block pt-2 pb-1 bg-success text-light border">
                <span><strong class="text-size-14">WhatsApp</strong></span>
                <i class="fa fa-whatsapp text-size-16 ml-3"></i>
              </button>
              <br>
              <label>Visualizar Botón</label>
              <select (change)="updateShowButton($event, 'whatsapp')" class="form-control form-control-sm text-normal"
                [(ngModel)]="configuracion.visibilidadBtnWhatsapp" title="Aplicar costo de envío">
                <option value="1">Si</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="col-lg-4 text-size-13 text-center mt-1 mb-2">
            <div class="border rounded p-2">
              <button type="button" class="btn-sm btn-block pt-2 pb-1 bg-secondary text-light border">
                <span><strong class="text-size-14">Pago en la entrega</strong></span>
                <i class="fa fa-handshake-o text-size-16 ml-3"></i>
              </button>
              <br>
              <label>Visualizar Botón</label>
              <select (change)="updateShowButton($event, 'entrega')" class="form-control form-control-sm text-normal"
                [(ngModel)]="configuracion.valor_minimo_compra" title="Visualizar boton">
                <option value="1">Si</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="form-group text-center w-100 mt-3">
            <button type="button text-center" class="btn btn-dark btn-sm" (click)="updateConfiguration(configuracion)">
              <i class="far fa-save"></i>
            </button>
          </div>
        </div>

        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2 border-bottom">
            <p class="text-center"><strong class="text-size-13 text-normal">Costos de envío</strong></p>
          </div>
          <div class="col-lg-12 text-size-13 text-center mt-1">
            <table class="table table-hover ">
              <thead class="thead-light">
                <tr class="text-center text-size-14">
                  <th scope="col">Codigo</th>
                  <th scope="col" class="text-left">Provincia</th>
                  <th scope="col" class="text-left">Costo de envío</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let p of provinces" class="text-size-12 text-normal text-center">
                  <td>{{p.codigoProv}}</td>
                  <td class="text-left">{{p.descripProv}} / {{p.capital}}</td>
                  <td>
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-sm">$</span>
                      </div>
                      <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                        placeholder="Ingrese el valor" [(ngModel)]="p.precio_envio">
                    </div>
                  </td>
                  <td class="text-success text-size-20" (click)="updateCostShippingProvince(p)"><i
                      class="fa fa-check-circle i-cost-send" aria-hidden="true"></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-1 text-minuscula-form" *ngIf="menu.imagenesBanner">
      <div class="col-lg-12">
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-12 text-center mb-2">
            <label class="col-form-label"> <strong class="text-size-13">Nuevo Banner</strong></label>
            <p class="text-danger text-size-12">El tamaño recomendado de la imagen debe ser de 950 * 500 px</p>
            <button class="btn btn-outline-dark" (click)="ModalUploadFile(uploadFileModal, 'Banner', '')">
              <i class="fas fa-plus-circle"></i>
            </button>
          </div>
        </div>
        <div class="row mt-2 ml-0 mr-0">
          <div class="col-lg-12 table-responsive">
            <table class="table table-hover text-size-13">
              <thead class="thead-dark">
                <tr class="text-center text-minuscula-form">
                  <th scope="col">id</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Imagen</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let b of imagesBanner" class="text-size-12 text-minuscula-texto-negro text-center">
                  <th scope="row">{{b.id_img_banner}}</th>
                  <td>{{b.nombre}}</td>
                  <td>
                    <!-- <img src="https://drive.google.com/uc?export=view&id={{b.url}}" class="mr-2" style="max-width: 150px"> -->
                    <img [src]="urlBase+b.url+'?alt=media'" class="mr-2" style="max-width: 150px">
                    </td>
                  <td (click)="ModaldeleteFile(deleteFileModal, b, 'Banner')"><i
                      class="far fa-trash-alt text-danger text-size-16"></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-1 text-minuscula-form" *ngIf="menu.imagenesGrupos">
      <div class="col-lg-12">
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2">
            <label class="col-form-label"> <strong class="text-size-13">Imagenes Grupos</strong></label>
            <p class="text-danger text-size-12">El tamaño recomendado de la imagen debe ser de 1024 * 1024 px</p>
          </div>

          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2">
            <form>
              <div class="input-group input-group-sm mb-3">
                <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                  name="search" placeholder="Buscar Grupo ..." [(ngModel)]="searchProd"
                  (ngModelChange)="searchGroupName(searchProd)">
                <div class="input-group-prepend">
                  <button type="button" class="btn btn-success btn-sm btn-block" (click)="searchGroupName(searchProd)"
                    type="submit"><i class="fas fa-search"></i></button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="row mt-2 ml-0 mr-0">
          <div class="col-lg-12 table-responsive text-size-13 text-center mt-2 mb-2">
            <table class="table table-hover table-striped text-size-13">
              <thead class="thead-dark">
                <tr class="text-center">
                  <th scope="col">id</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Imagen</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let g of groupsFree" class="text-size-13 text-normal text-center">
                  <th scope="row" *ngIf="g.ocult == false">{{g.codigo}}</th>
                  <td *ngIf="g.ocult == false">
                    <strong>{{g.nombre}}</strong>

                    <div class="text-size-11 mt-2 border rounded pl-1 pr-1">
                      <label class="float-left mt-1">Descripción</label>
                      <form>
                        <div class="input-group input-group-sm mb-3">
                          <input type="text" class="form-control" aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm" name="search" placeholder="Descripción"
                            [(ngModel)]="g.descripcion">
                        </div>
                      </form>
                      <p class="text-size-12 border rounded pb-2 bg-light">
                        <input class="mt-2" type="checkbox" [(ngModel)]="g.vista_web"
                          (change)="updateAtributesGroup($event, g, 'vista_web')">
                        <span class="ml-1 mt-2">Vista web</span>
                      </p>
                    </div>

                    <div class="text-size-12 mt-3">
                      <button type="button" class="btn btn-outline-dark btn-sm btn-sm pt-0 pb-0"
                        (click)="modalConfigurationSubGroup(configurationSubgrupoModal, g)">
                        <i class="fa fa-wrench" aria-hidden="true"></i>
                        <span class="text-size-11 ml-1">Configurar Subgrupos</span>
                      </button>
                    </div>
                  </td>
                  <td *ngIf="g.ocult == false" (click)="ModalUploadFile(uploadFileModal, 'Groups', g)">
                    <img [src]="urlBase+g.img+'?alt=media'" class="mr-2"
                      style="max-width: 150px" *ngIf="g.img">
                    <!-- <img src="https://drive.google.com/uc?export=view&id={{g.img}}" class="mr-2"
                      style="max-width: 150px" *ngIf="g.img"> -->
                    <img src="../../../../assets/img/imgDefault/sinProd.png" class="mr-2"
                      style="max-width: 150px" *ngIf="!g.img">
                    <!-- <img src="https://drive.google.com/uc?export=view&id=1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2" class="mr-2"
                      style="max-width: 150px" *ngIf="!g.img"> -->
                    <i class="fas fa-edit ml-4" title="Editar imagen del grupo"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-1 text-minuscula-form" *ngIf="menu.imagenesProductos">
      <div class="col-lg-12">
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2">
            <label class="col-form-label"> <strong class="text-size-13">Administrar imagenes y atributos de
                productos</strong></label>
            <p class="text-danger text-size-12">El tamaño recomendado de la imagen debe ser de 800 * 800 px</p>
          </div>
        </div>

        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2 border-bottom">
            <p class="text-center"><strong class="text-size-13 text-normal">Seleccione los atributos que desea
                mostrar</strong></p>
          </div>

          <div class="col-lg-12 table-responsive text-size-13 text-center mt-2 mb-2">
            <table class="table table-hover table-striped text-size-13">
              <thead>
                <tr>
                  <th scope="col">
                    <label class="mr-2">Descripción </label>
                    <input type="checkbox" value="" [(ngModel)]="configuracion.descripcion_producto"
                      (change)="viewAttribuesProduct($event, 'descripcion')">
                  </th>
                  <th scope="col">
                    <label class="mr-2">Marca</label>
                    <input type="checkbox" value="" [(ngModel)]="configuracion.marca_producto"
                      (change)="viewAttribuesProduct($event, 'marca')">
                  </th>
                  <th scope="col">
                    <label class="mr-2">Peso</label>
                    <input type="checkbox" value="" [(ngModel)]="configuracion.peso_producto"
                      (change)="viewAttribuesProduct($event, 'peso')">
                  </th>
                  <th scope="col">
                    <label class="mr-2">Medida</label>
                    <input type="checkbox" value="" [(ngModel)]="configuracion.medida_producto"
                      (change)="viewAttribuesProduct($event, 'medida')">
                  </th>
                  <th scope="col">
                    <label class="mr-2">Ubicación</label>
                    <input type="checkbox" value="" [(ngModel)]="configuracion.ubicacion_producto"
                      (change)="viewAttribuesProduct($event, 'ubicacion')">
                  </th>
                  <th scope="col">
                    <label class="mr-2">Origen</label>
                    <input type="checkbox" value="" [(ngModel)]="configuracion.origen_producto"
                      (change)="viewAttribuesProduct($event, 'origen')">
                  </th>
                  <th scope="col">
                    <label class="mr-2">Cant. por bulto</label>
                    <input type="checkbox" value="" [(ngModel)]="configuracion.cantidadxbulto_producto"
                      (change)="viewAttribuesProduct($event, 'cantidadxbulto')">
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div class="divSelect pt-2">
          <form [formGroup]="formStock">
          <div class="form-check">
            <input formControlName="checkStock" (change)="filtroStock($event)" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
             STOCK
            </label>
          </div>  
        </form>    
        </div>

        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-6 text-size-13 text-center mt-3">
            <p class="text-center">
              <select class="form-control form-control-sm text-normal" (change)="selectGroup($event.target.value)">
                <option selected disabled>Seleccione Grupo</option>
                <option value="{{g.codigo}}" *ngFor="let g of groups">{{g.nombre}}</option>
              </select>
            </p>
          </div>
          <div class="col-lg-6 text-size-13 text-center mt-3">
            <form>
              <div class="input-group input-group-sm">
                <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                  name="search" placeholder="Buscar producto nombre / código" [(ngModel)]="searchProd">
                <div class="input-group-prepend">
                  <button type="button" class="btn btn-success btn-sm btn-block" (click)="searchProduct(searchProd)"
                    type="submit">
                    <i class="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="col-lg-12 table-responsive text-size-13 text-center mt-2 mb-2">
            <table class="table table-hover table-striped text-size-13">
              <thead class="thead-dark">
                <tr class="text-center">
                  <th scope="col">Código</th>
                  <th scope="col" class="text-left">Producto</th>
                  <th scope="col">Img 1</th>
                  <th scope="col">Img 2</th>
                  <th scope="col">Img 3</th>
                  <th scope="col">Img 4</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let p of products" class="text-size-11 text-normal text-center">
                  <th scope="row">{{p.pro_cod}}</th>
                  <td class="text-left">
                    <p>{{p.pro_nom}}</p>
                <tr>
                  <button type="button" class="btn btn-outline-dark btn-sm pt-0 pb-0"
                    (click)="modalConfigurationAtributesProduct(configurationProductModal, p)">
                    <i class="fa fa-wrench" aria-hidden="true"></i>
                    <span class="text-size-11 ml-1">Configurar</span>
                  </button>
                </tr>
                </td>

                <td (click)="ModalUploadFile(uploadFileModal, 'Img_1', p)">
                  <p class="mt-1"><i class="fas fa-edit text-size-16"></i></p>
                  <img [src]="urlBase+p.imagen_uno+'?alt=media'" style="max-width: 150px"
                    *ngIf="p.imagen_uno">
                    <img src="../../../../assets/img/imgDefault/sinProd.png"
                    style="max-width: 150px" *ngIf="!p.imagen_uno">
                  <!-- <img src="https://drive.google.com/uc?export=view&id={{p.imagen_uno}}" style="max-width: 150px"
                    *ngIf="p.imagen_uno"> -->

                  <!-- <img src="https://drive.google.com/uc?export=view&id=1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2"
                    style="max-width: 150px" *ngIf="!p.imagen_uno"> -->
                </td>

                <td (click)="ModalUploadFile(uploadFileModal, 'Img_2', p)">
                  <p><i class="fas fa-edit text-size-16"></i></p>
                  <img  [src]="urlBase+p.imagen_dos+'?alt=media'" style="max-width: 150px"
                    *ngIf="p.imagen_dos">
                  <img src="../../../../assets/img/imgDefault/sinProd.png"
                    style="max-width: 150px" *ngIf="!p.imagen_dos">
                </td>

                <td (click)="ModalUploadFile(uploadFileModal, 'Img_3', p)">
                  <p><i class="fas fa-edit text-size-16"></i></p>
                  <img [src]="urlBase+p.imagen_tres+'?alt=media'" style="max-width: 150px"
                    *ngIf="p.imagen_tres">
                  <img src="../../../../assets/img/imgDefault/sinProd.png"
                    style="max-width: 150px" *ngIf="!p.imagen_tres">
                </td>

                <td (click)="ModalUploadFile(uploadFileModal, 'Img_4', p)">
                  <p><i class="fas fa-edit text-size-16"></i></p>
                  <img [src]="urlBase+p.imagen_cuatro+'?alt=media'"  style="max-width: 150px"
                    *ngIf="p.imagen_cuatro">
                  <img src="../../../../assets/img/imgDefault/sinProd.png"
                    style="max-width: 150px" *ngIf="!p.imagen_cuatro">
                </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-1 text-minuscula-form" *ngIf="menu.coloresProductos">
      <div class="col-lg-12">
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2 border-bottom">
            <label class="col-form-label"> <strong class="text-size-13">Seleccione el nuevo color que desea
                agregar</strong></label>
          </div>
          <div class="col-lg-2 text-size-13 text-center mt-2 mb-2">
            <label for="nombre">Seleccione del color *</label>
            <input class="form-control form-control-sm text-normal" type="color" name="" id=""
              [(ngModel)]="colorAddTable.codigo">
          </div>
          <div class="col-lg-9 text-size-13 text-center mt-2 mb-2">
            <label for="nombre">Nombre del color *</label>
            <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name="" id=""
              [(ngModel)]="colorAddTable.nombre">
          </div>
          <div class="col-lg-1 text-size-13 text-center mt-2 mb-2">
            <label class="text-oculto">*</label>
            <button class="btn btn-dark btn-sm form-control form-control-sm" (click)="addColorTable(colorAddTable)">
              <i class="far fa-save"></i>
            </button>
          </div>
        </div>

        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2 table-responsive">
            <table class="table table-hover table-striped text-size-13">
              <thead class="thead-dark">
                <tr class="text-center text-size-14">
                  <th scope="col">Nombre</th>
                  <th scope="col">Color</th>
                  <th scope="col">Codigo</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let c of colorsTable" class="text-size-12 text-normal text-center">
                  <td>{{c.nombre}}</td>
                  <td class="pt-0 pb-0">
                    <span class="text-size-30" [ngStyle]="{'color':c.codigo}">
                      <i class="fa fa-circle border-color" aria-hidden="true"></i>
                    </span>
                  </td>
                  <td>{{c.codigo}}</td>
                  <td (click)="ModaldeleteFile(deleteFileModal, c, 'Color')"
                    *ngIf="c.id_empresa == information.id_empresa"><i class="fa fa-trash-o text-danger"
                      aria-hidden="true"></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-1 text-minuscula-form" *ngIf="menu.guiaTallas">
      <div class="col-lg-12">
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2">
            <label class="col-form-label"> <strong class="text-size-13">Administrar Guía de Tallas</strong></label>
            <hr>
            <button class="btn btn-outline-dark btn-sm" (click)="modalCreateGuiaTallas(createGuiaTallasModal)">
              <i class="fa fa-cog"></i>
            </button>
          </div>
        </div>
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-4 text-size-13 text-center mt-2 mb-2 table-responsive" *ngIf="guiaTallasAll.length > 0">
            <table class="table table-hover table-striped table-bordered text-size-13">
              <thead class="thead-dark">
                <tr class="text-left">
                  <th class="w-75">Grupo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let g of guiaTallasAll" class="text-size-12 text-normal">
                  <td class="text-left tipo-cursor" (click)="viewGuiaTalla(g, guiaTallasAll)">
                    <span>{{g.nombre_grupo}}</span>
                  </td>
                  <td (click)="viewGuiaTalla(g, guiaTallasAll)">
                    <i class="fa fa-eye text-dark icn-talla" aria-hidden="true" *ngIf="!g.view"></i>
                    <i class="fa fa-eye-slash text-danger icn-talla" aria-hidden="true" *ngIf="g.view"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-8 text-size-13 text-center mt-2 mb-2 table-responsive" *ngIf="guiaTallasAll.length > 0">
            <table class="table table-hover table-bordered table-striped table-sm  text-size-13">
              <thead class="bg-success text-light">
                <tr class="text-left">
                  <th>Medida</th>
                  <th>Descripcion</th>
                  <td>Tallas</td>
                </tr>
              </thead>
              <tbody>
                <tr class="text-left" *ngFor="let gt of guiaTallas">
                  <td>{{gt.medida}}</td>
                  <td>{{gt.descripcion}}</td>
                  <td>
                <tr class="mb-0 bb-0">
                  <th *ngFor="let gtd of gt.detalle" class="border-secondary text-center p-0 text-size-12">
                    <span class="w-100 float-left border-bottom border-secondary"><strong>{{gtd.nombre}}</strong></span>
                    <span>{{gtd.detalle}}</span>
                  </th>
                </tr>
                </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-1 text-minuscula-form" *ngIf="menu.promociones">
      <div class="col-lg-12">
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2 border-bottom">
            <label class="col-form-label"> <strong class="text-size-13">Título Sección de Promociones</strong></label>
          </div>
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2">
            <div class="input-group input-group-sm">
              <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                [(ngModel)]="configuracion.tituloPromocion">
              <div class="input-group-append">
                <button type="button" class="btn btn-success btn-sm"
                  (click)="updateTitlePromotion(configuracion.tituloPromocion)">
                  <i class="fa fa-floppy-o"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2">
            <label class="col-form-label"> <strong class="text-size-13">Nueva Promoción</strong></label>
            <p class="text-danger text-size-12">El tamaño recomendado de la imagen debe ser de 800 * 400px</p>
            <button class="btn btn-dark btn-sm" (click)="modalcreatePromotion(createPromotionModal)">
              <i class="fas fa-plus-circle"></i>
            </button>
          </div>
        </div>
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light" *ngIf="promotions.length > 0">
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2 table-responsive">
            <table class="table table-hover table-striped table-bordered text-size-13">
              <thead class="thead-dark">
                <tr class="text-center text-minuscula-form">
                  <th scope="col">Promoción</th>
                  <th scope="col">Descripción</th>
                  <th scope="col">Imagen</th>
                  <th scope="col">Producto</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let p of promotions" class="text-size-12 text-normal text-center">
                  <td class="text-justify">{{p.nombrePromocion}}</td>
                  <td class="text-justify">{{p.descripcion}}</td>
                  <td>
                    <img  [src]="urlBase+p.imgPromocion+'?alt=media'"  style="max-width: 150px"
                      *ngIf="p.imgPromocion">
                    <!-- <img src="https://drive.google.com/uc?export=view&id={{p.imgPromocion}}" style="max-width: 150px"
                      *ngIf="p.imgPromocion"> -->
                    <img src="../../../../assets/img/imgDefault/sinProd.png"
                      style="max-width: 150px" *ngIf="!p.imgPromocion">
                  </td>
                  <td>
                    <p>{{p.nombre_producto}}</p>
                  </td>
                  <td>
                    <i class="far fa-trash-alt text-danger text-size-16"
                      (click)="ModaldeleteFile(deleteFileModal, p, 'Promotion')"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-1 text-minuscula-form" *ngIf="menu.generarCatalogo">
      <div class="col-lg-12">
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <!-- Grupo -->
          <div class="col-lg-2 text-size-13 text-center mt-2 mb-2">
            <span class="text-size-12">Seleccione el Grupo</span>
            <select class="form-control form-control-sm text-normal" (change)="getSubGroups($event.target.value)">
              <option value="{{g.idgrupo}}" *ngFor="let g of groups">{{g.nombre}}</option>
            </select>
          </div>
          <!-- Subgrupo -->
          <div class="col-lg-2 text-size-13 text-center mt-2 mb-2" *ngIf="catalogue.subGrupos.length > 0">
            <span class="text-size-12">Seleccione el Subgrupo</span>
            <select class="form-control form-control-sm text-normal"
              (change)="getProductsGrupoSubgrupo($event.target.value)">
              <option value="{{s.id_sub}}" *ngFor="let s of catalogue.subGrupos"> {{s.nombre}}</option>
            </select>
          </div>
          <!-- Origen -->
          <div class="col-lg-2 text-size-13 text-center mt-2 mb-2">
            <span class="text-size-12">Origen</span>
            <select class="form-control form-control-sm text-normal" [(ngModel)]="this.catalogue.origen_select"
              (change)="filterProductForOrigin($event.target.value)">
              <option value="{{o.id}}" *ngFor="let o of catalogue.origen_arr">{{o.nombre}}
              </option>
            </select>
          </div>
          <!-- Marca -->
          <div class="col-lg-2 text-size-13 text-center mt-2 mb-2">
            <span class="text-size-12">Marca</span>
            <select class="form-control form-control-sm text-normal" [(ngModel)]="this.catalogue.marca_select"
              (change)="filterProductForMarca($event.target.value)">
              <option value="{{m.id}}" *ngFor="let m of catalogue.marcas">{{m.nombre}}</option>
            </select>
          </div>
          <!-- Buscador -->
          <div class="col-lg-4 text-size-13 text-center mt-2 mb-2">
            <span class="text-size-12 text-oculto">*</span>
            <form>
              <div class="input-group input-group-sm mb-3">
                <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                  name="search" placeholder="Buscar ..." [(ngModel)]="catalogue.search">
                <div class="input-group-prepend">
                  <button type="button" class="btn btn-success btn-sm btn-block"
                    (click)="searchProductsCatalogue(catalogue.search)" type="submit">
                    <i class="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light" *ngIf="catalogue.view_products">
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2">
            <p class="pt-2 pb-2 border-bottom"><strong class="text-size-13 text-normal">Seleccione los atributos que
                desea visualizar</strong></p>
          </div>
          <div class="col-lg-12 text-size-13 text-center mb-2 table-responsive">
            <table class="table table-bordered text-size-13">
              <thead>
                <tr>
                  <th scope="col">
                    <label class="mr-2">T.precio</label>
                    <select (change)="selectTypePrice($event.target.value)">
                      <option value="{{t.tipoprecio}}" *ngFor="let t of pricesTypes">
                        {{t.tipoprecio}}
                      </option>
                    </select>
                    <input class="ml-2 mr-2" type="checkbox" value="show_price" checked (change)="viewAttributes($event)">
                  </th>
                  <th scope="col">
                    <label class="mr-2">Stock</label>
                    <input type="checkbox" value="stock" checked (change)="viewAttributes($event)">
                  </th>
                  <th scope="col">
                    <label class="mr-2">Descripción</label>
                    <input type="checkbox" value="description" checked (change)="viewAttributes($event)">
                  </th>
                  <th scope="col">
                    <label class="mr-2">Marca</label>
                    <input type="checkbox" value="marca" checked (change)="viewAttributes($event)">
                  </th>
                  <th scope="col">
                    <label class="mr-2">Peso</label>
                    <input type="checkbox" value="peso" checked (change)="viewAttributes($event)">
                  </th>
                  <th scope="col">
                    <label class="mr-2">Medida</label>
                    <input type="checkbox" value="medida" checked (change)="viewAttributes($event)">
                  </th>
                  <th scope="col">
                    <label class="mr-2">Ubicación</label>
                    <input type="checkbox" value="ubicacion" checked (change)="viewAttributes($event)">
                  </th>
                  <th scope="col">
                    <label class="mr-2">Origen</label>
                    <input type="checkbox" value="origen" checked (change)="viewAttributes($event)">
                  </th>
                  <th scope="col">
                    <label class="mr-2">Cant. por bulto</label>
                    <input type="checkbox" value="cant_bulto" checked (change)="viewAttributes($event)">
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-12" *ngIf="catalogue.view_products">
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-12 text-size-13 text-center mb-2">
            <button class="btn btn-outline-danger mt-3" printSectionId="section-pdf" ngxPrint
              *ngIf="catalogue.view_products">
              <span class="text-size-13">Generar Catálogo</span>
              <i class="far fa-file-pdf ml-3"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- Todos, marca, origen, busqueda -->
      <div style="width: 100%; border-radius: 5px; padding: 5px;" id="section-pdf" #content
        *ngIf="catalogue.view_products">
        <div
          style="width: 100%; border: 1px solid #e5e7e9; border-radius: 5px; background-color: #f8f9fa; text-align: center;">
          <!-- [src]="urlBase+configuracion?.imgLogo+'?alt=media' -->
          <img style="width: 150px; margin: 1em 0 1em 0;"
           [src]="urlBase+configuracion?.imgLogo+'?alt=media'">
          <!-- <img style="width: 150px; margin: 1em 0 1em 0;"
            src="https://drive.google.com/uc?export=view&id={{configuracion?.imgLogo}}"> -->
        </div>
        <div
          style="width: 100%; border: 1px solid #e5e7e9; border-radius: 5px; background-color: #f8f9fa; text-align: center; margin-top: 10px;">
          <p
            style="background-color: #fff; font-size: 14px; padding: 5px; margin: 1em ; border-radius: 8px; border: 1px solid #f8f9fa;">
            CATALOGO DE PRODUCTOS</p>
        </div>
        <div
          style="width: 100%; border: 1px solid #e5e7e9; border-radius: 5px; background-color: #f8f9fa; padding: 12px; margin-top: 10px;">

          <div
            style="width: 100%; border: 1px solid #e5e7e9; border-radius: 5px; background-color: #f8f9fa; display: flex; margin-top: 8px;"
            *ngFor="let p of catalogue.products">

            <table
              style="width:100%; text-align: center; font-size: 14px; background-color: #000; color: #fff; border: 1px solid #e5e7e9; border-radius: 8px;"
              *ngIf="p.id_producto < 0 && p.view == true">
              <tr>
                <td style="width: 50%; padding: 6px;"><span><strong>GRUPO: </strong>
                    {{p.nombre_grupo}}</span></td>
                <td style="width: 50%; padding: 6px;"><span><strong>SUBGRUPO: </strong>
                    {{p.nombre_subgrupo}}</span></td>
              </tr>
            </table>

            <div style="width: 15%; vertical-align: middle; flex-wrap: wrap;" *ngIf="p.id_producto > 0">
              <img *ngIf="p.imagenPrincipal != '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'"  width="100%" style="padding: 10px 0px 0px 10px;"
                [src]="urlBase+p.imagenPrincipal+'?alt=media'" alt="" >
                <img *ngIf="p.imagenPrincipal == '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'" width="100%" style="padding: 10px 0px 0px 10px;" src="../../../../assets/img/imgDefault/sinProd.png"  >
              <!-- <img width="100%" style="padding: 10px 0px 0px 10px;"
                src="https://drive.google.com/uc?export=view&id={{p.imagenPrincipal}}" alt=""> -->
            </div>

            <div style="width: 85%; padding: 10px 0px 10px 20px; flex-wrap: wrap;" *ngIf="p.id_producto > 0">
              <span style="color: black; font-size: 13px;">{{p.id_producto}} /
                {{p.pro_nom}}</span>
              <br *ngIf="catalogue.show_price">
              <span style="font-size: 13px;" *ngIf="catalogue.show_price">
                <strong style="font-size: 11px;">PVP.</strong>
                ${{p.precioReal | number: '1.2'}}
              </span>
              <br *ngIf="catalogue.stock">
              <span style="font-size: 13px;" *ngIf="catalogue.stock">
                <strong style="font-size: 12px;">Stock : </strong>
                {{p.stockactual}}
              </span>
              <br *ngIf="catalogue.description">
              <span style="font-size: 12px;" *ngIf="catalogue.description">
                <strong>Descripcion:</strong>
                {{p.descripcion}}
              </span>
              <br *ngIf="catalogue.marca">
              <span style="font-size: 12px;" *ngIf="catalogue.marca">
                <strong>Marca:</strong>
                {{p.marca_nombre}}
              </span>
              <br *ngIf="catalogue.peso">
              <span style="font-size: 12px;" *ngIf="catalogue.peso">
                <strong>Peso / Piezas:</strong>
                {{p.peso}}
              </span>
              <br *ngIf="catalogue.medida">
              <span style="font-size: 12px;" *ngIf="catalogue.medida">
                <strong>Medida:</strong>
                {{p.valor_medida}} {{p.unidad_medida}}
              </span>
              <br *ngIf="catalogue.ubicacion">
              <span style="font-size: 12px;" *ngIf="catalogue.ubicacion">
                <strong>Ubicación:</strong>
                {{p.ubicacion}}
              </span>
              <br *ngIf="catalogue.origen">
              <span style="font-size: 12px;" *ngIf="catalogue.origen">
                <strong>origen: </strong>
                <span *ngIf="p.origen == 1"> Nacional</span>
                <span *ngIf="p.origen == 2"> Importado</span>
              </span>
              <br *ngIf="catalogue.cant_bulto">
              <span style="font-size: 12px;" *ngIf="catalogue.cant_bulto">
                <strong>Cantidad por bulto:</strong>
                {{p.cant_bulto}}
              </span>
            </div>

          </div>

        </div>
      </div>
      <div class="w-100 text-center" *ngIf="catalogue.view_products == false">
        <img class="w-25" src="https://drive.google.com/uc?export=view&id=1_bZD-9CEmvOAdxd08WjfuKWIuhnIxc-2" alt="">
      </div>
    </div>

    <div class="row m-1 text-minuscula-form" *ngIf="menu.pixelFacebook">
      <div class="col-lg-12">
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2">
            <label class="col-form-label btn-facebook w-100"> <strong class="text-size-13">Inserte su código fuente
                otorgado por pixel de facebook, para ser implementado en su tienda</strong></label>
          </div>
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2">
            <textarea style="font-size: 13px;" class="form-control form-control-sm text-normal text-justify" rows="20"
              [(ngModel)]="configuracion.pixel_facebook"></textarea>
          </div>
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2">
            <button class="btn btn-dark btn-sm" (click)="updatePixelfacebook(configuracion.pixel_facebook)">
              <i class="far fa-save"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-1 text-minuscula-form" *ngIf="menu.credenciales">
      <div class="col-lg-12">
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2 border-bottom">
            <label class="col-form-label"> <strong class="text-size-13">Actualizar su contraseña</strong></label>
          </div>
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2">

            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend w-25">
                <span class="input-group-text w-100" id="basic-addon3">Nombre Usuario</span>
              </div>
              <input type="text" class="form-control" id="basic-url" aria-describedby="basic-addon3" disabled
                [(ngModel)]="administration.data.usuario">
            </div>

            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend w-25">
                <span class="input-group-text w-100" id="basic-addon3">Contraseña Actual</span>
              </div>
              <input type="password" class="form-control" id="basic-url" aria-describedby="basic-addon3"
                autocomplete="on" [(ngModel)]="administration.validate.current">
            </div>

            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend w-25">
                <span class="input-group-text w-100" id="basic-addon3">Contraseña Nueva</span>
              </div>
              <input type="password" class="form-control" id="basic-url" aria-describedby="basic-addon3"
                autocomplete="off" [(ngModel)]="administration.validate.new">
            </div>

            <button type="button" class="btn btn-dark btn-sm" (click)="updateAdministration(administration)">
              <i class="far fa-save"></i>
            </button>

          </div>
        </div>
      </div>
    </div>

    <div class="row m-1 text-minuscula-form" *ngIf="menu.servicios">
      <div class="col-lg-12">
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2 border-bottom">
            <label class="col-form-label"> <strong class="text-size-13">Administrar Botones Portada</strong></label>
          </div>
          
          <div>
            <button class="btn btn-sm rounded-2  btn-outline-success" (click)="openModal(createBtnAcceso,'create','')" >
                Crear Nuevo  <i class="bi bi-plus"></i>
            </button>
          </div>
          <div class="table-responsive mt-2 " >
            <table class="table table-striped  rounded-3" style="font-size: 13px; ">
              <thead class="text-center  ">
                <tr>
                  <th scope="col">Título</th>
                  <th scope="col">URL</th>
                  <th scope="col">Color botón</th>
                  <th scope="col">Color letra</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="botones.length==0 " >
                  <td colspan="6" >
                    <div class=" divSelect py-5" >
                      <span style="font-size: 24px; font-weight: 600; color:gray " >Aún no ha creado botones  </span>
                    </div>
                  </td>
                </tr>
                <ng-container  *ngIf="botones.length>0 ">
                  <tr class="" *ngFor="let btn of botones"  >
                    <!-- <th scope="row">1</th> -->
                    <td>{{btn.titulo}}</td>
                    <td>{{btn.url}}</td>
                    <td class="text-center">
                      <span class="badge " style="width: 2em; height: 1.5em; border: 1px solid gray; " [ngStyle]="{'background-color':btn.colorBoton }">
                        <!-- color -->
                        <h6></h6>
                        
                       </span>
                    </td>
                    <td class="text-center">
                      <span class="badge " style="width: 2em; height: 1.5em;border: 1px solid gray;" [ngStyle]="{'background-color':btn.colorLetra }">
                        <h6></h6>       
                       </span>
                    </td>
                    <td>
                      <div class="btn-group-sm">
                        <button class="btn btn-sm rounded-3 btn-success mr-1"  (click)="openModal(createBtnAcceso,'edit', btn)" >
                          <i class="bi bi-pencil"></i>
                        </button>
                        <button class="btn btn-sm rounded-3 btn-danger" (click)="deleteBtn(btn)" >
                          <i class="bi bi-trash3-fill"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-container>

              </tbody>
            </table>
          </div>

        </div>
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light mt-3">
          <div class="col-lg-12 text-size-13 text-center mt-2 mb-2 border-bottom" style="background-color: #f2f2f4;" >
            <label class="col-form-label"> <strong class="text-size-13">Administrar Servicios Inicio</strong></label>
          </div>
          
          <div>
            <button class="btn btn-sm rounded-2  btn-outline-success" (click)="openModal(createService,'create','')" >
                Crear Nuevo  <i class="bi bi-plus"></i>
            </button>
          </div>
          <div class="table-responsive mt-2 " >
            <table class="table table-striped  rounded-3" style="font-size: 13px; ">
              <thead class="text-center" >
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Descripción</th>
                  <th scope="col">Imagen</th>
                  <th scope="col">Color letra</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody >
                <tr *ngIf="servicios.length==0 " >
                  <td colspan="6" >
                    <div class=" divSelect py-5" >
                      <span style="font-size: 24px; font-weight: 600; color:gray " >Aún no ha creado servicios  </span>
                    </div>
                  </td>
                </tr>
                <ng-container  *ngIf="servicios.length>0 ">
                  <tr class="" *ngFor="let serv of servicios"  >
                    <!-- <th scope="row">1</th> -->
                    <td>{{serv.nombre}}</td>
                    <td>{{serv.detalle}}</td>
                    <td class="text-center">
                      <!-- urlBase+serv.image -->
                      <span type="button" (click)="redirecTO(urlBase+serv.imagen+'?alt=media')"  > Ver </span> 
                    </td>
                    <td class="text-center">
                      <span class="badge " style="width: 2em; height: 1.5em;border: 1px solid gray;" [ngStyle]="{'background-color':serv.colorLetra }">
                        <h6>
                          
                        </h6>       
                       </span>
                    </td>
                    <td>
                      <div class="btn-group-sm">
                        <button class="btn btn-sm rounded-3 btn-success mr-1"  (click)="openModalService(createService,'edit', serv)" >
                          <i class="bi bi-pencil"></i>
                        </button>
                        <button class="btn btn-sm rounded-3 btn-danger" (click)="deleteBtnService(serv)" >
                          <i class="bi bi-trash3-fill"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-container>

              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>

    <!-- <div class="container-fluid   m-1 text-minuscula-form" *ngIf="menu.servicios">
        <div class="row w-100 rounded-4 border p-4 " style="background-color: #f2f2f4;"  >
          <div class="col-12">
              <h6 class="fw-bold text-center" >Configuración Servicios Inicio</h6>
          </div>
        </div>

      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic itaque iste pariatur autem quasi reprehenderit laudantium ab reiciendis! Quos harum alias, labore dolor earum doloribus voluptas quidem nesciunt distinctio accusamus!</p>
    
    
    </div> -->

    <div class="row m-1 text-minuscula-form" *ngIf="menu.vaucher">
      <div class="col-lg-12">
        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
          <div class="col-lg-6 mt-2 mb-2">
            <div class="form-group text-size-13">
              <label>Valor</label>
              <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name="" id="" placeholder="Ingrese el valor del vaucher" [(ngModel)]="vaucher.valor_transaccion">
            </div>
            <div class="form-group text-size-13">
              <label>Descripción</label>
              <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name="" id="" placeholder="Detalle del vaucher" [(ngModel)]="vaucher.detalle_transaccion">
            </div>
            <div class="form-group text-size-13 w-100 text-center">
              <button type="button" class="btn btn-outline-success btn-sm" (click)="generateVaucher()">
                <i class="fa fa-credit-card-alt"></i>
              </button>
            </div>
          </div>
          <div class="col-lg-6 mt-2 mb-2 text-center" *ngIf="myAngularxQrCode">
            <qrcode [qrdata]="myAngularxQrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
            <button type="button" class="btn btn-warning btn-sm mt-1" (click)="openLik()">
              <span class="text-size-13 mr-2">Abrir enlace</span>
              <i class="fa fa-external-link text-size-16"></i>
            </button>
            <br>
            <button type="button" class="btn btn-success btn-sm mt-1" (click)="sharedWhatsapp()">
              <span class="text-size-13 mr-2">Compartir enlace</span>
              <i class="fa fa-whatsapp text-size-16"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- FIN NUEVO MENU -->

<!-- Administrar imagenes-->
<ng-template #uploadFileModal let-modal>

  <div class="row pt-3 text-minuscula-texto-negro text-size-14">
    <div class="col-9 text-center">
      <p>Seleccione la imagen</p>
    </div>
    <div class="col-2 float-left">
      <button type="button" class="close text-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <div class="row modal-body text-minuscula-texto-negro text-center">
    <div class="col-lg-10">
      <input style="font-size: 13px;" class="form-control" #files type="file" accept="image/*">
    </div>
    <div class="col-lg-2">
      <button type="button" ngbAutofocus class="btn btn-outline-dark ml-1" (click)="modal.close(files)">
        <i class="fas fa-cloud-upload-alt"></i>

      </button>
    </div>
  </div>

</ng-template>

<ng-template #modaUploadLogo2 let-modal>
  <form [formGroup]="formLogoDos">
    <div class="modal-header">
      <h5 class="modal-title">Cargar Logo 2</h5>
      <!-- <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button> -->
    </div>
    <div class="modal-body">
      <div class="container">
        <div class="row divSelect">
          <div class="col-11">
            <div class="file-input-container"  >
              <input type="file" class="file-input" id="fileInput" (change)="onSelecLogo2($event)">
              <label for="fileInput" class="file-input-label"> 
                <i class="fa fa-cloud-upload pr-2" aria-hidden="true" ></i>
                Cargar imagen Logo 2
              </label>
            </div>
            <span *ngIf="flagFileLogo2"  for=""class="mb-2 text-center " >{{information.imgQrP}}</span>

          </div>
          <div class="col-11 mt-3">
            <label for="" style="font-style: nomral; font-weight: 500; font-size: 13px; " >Ingrese url a dónde desea redirigir</label>
            <!-- <textarea class="form-control " placeholder="Ingrese link de redireccion" style="height: 3em;" > -->
              <input formControlName="url" placeholder="Ingrese link de redireccion"  type="text" class="form-control rounded-2 ">
  
            <!-- </textarea> -->
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">

      <button type="button" class="btn btn-sm btn-outline-dark"  (click)="modal.dismiss('Cross click')">Cerrar</button>
      <button type="button" class="btn btn-sm btn-outline-success" [disabled]="!flagFileLogo2" (click)="saveLogo2(formLogoDos)" >Guardar</button>
    </div>
  </form>

</ng-template>

<!-- Alerta para eliminar un archivo -->
<ng-template #deleteFileModal let-modal>
  <div class="row pt-3 tipo-letra text-size-12">
    <div class="col-12 text-size-13 text-center pt-3" role="alert">
      <span class="alert alert-danger pl-5 pr-5">Seguro que desea eliminar</span>
    </div>
  </div>

  <div class="row modal-body tipo-letra text-center mt-3">
    <div class="col-12 text-center">
      <button type="button" class="btn btn-outline-success text-center" (click)="modal.close()">
        <i class="fas fa-check-circle"></i>
      </button>

      <button type="button" class="btn btn-outline-danger text-center ml-3" (click)="modal.dismiss('Cross click')">
        <i class="fas fa-minus-circle"></i>
      </button>
    </div>
  </div>
</ng-template>

<!-- Crear promoción -->
<ng-template #createPromotionModal let-modal>

  <div class="row pt-3 text-minuscula-texto-negro text-size-14">
    <div class="col-9 text-center">
      <!-- <p>Seleccione la imagen</p> -->
    </div>
    <div class="col-2 float-left">
      <button type="button" class="close text-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <div class="row modal-body text-minuscula-texto-negro">
    <div class="col-lg-12">
      <div class="form-group">
        <p>
          <i class="fas fa-signature mr-2 text-dark"></i>
          <label class="text-size-12 mb-3">Título <span class="text-danger">*</span></label>
          <input type="text" class="form-control form-control-sm" name="titulo"
            [(ngModel)]="newPromotion.nombrePromocion">
        </p>
      </div>

      <div class="form-group">
        <p>
          <i class="far fa-newspaper mr-2 text-dark"></i>
          <label class="text-size-12 mb-3">Descripción</label>
          <textarea style="font-size: 13px;" class="form-control form-control-sm text-normal" rows="3"
            [(ngModel)]="newPromotion.descripcion"></textarea>
        </p>
      </div>

      <div class="form-group">
        <p>
          <i class="fas fa-image mr-2 text-dark"></i>
          <label class="text-size-12 mb-3">Imagen <span class="text-danger">*</span></label>
          <input style="font-size: 13px;" class="form-control" #filesPromotion type="file" accept="image/*">
        </p>
      </div>

      <div class="form-group">
        <p>
          <i class="far fa-images mr-2 text-dark"></i>
          <label class="text-size-12 mb-3">Grupos</label>
          <select class="form-control form-control-sm text-normal" (change)="selectGroup($event.target.value)">
            <option value="{{g.codigo}}" *ngFor="let g of groups">{{g.nombre}}</option>
          </select>
        </p>
      </div>

      <div class="form-group">
        <p>
          <i class="fas fa-gifts mr-2 text-dark"></i>
          <label class="text-size-12 mb-3">Productos</label>
          <select class="form-control form-control-sm text-normal" (change)="selectProduct($event.target.value)">
            <option value="0" selected>Seleccione ...</option>
            <option value="{{p.pro_cod}}" *ngFor="let p of products">{{p.pro_nom}}</option>
          </select>
        </p>
      </div>

      <br>

      <button type="button" ngbAutofocus class="btn btn-outline-dark ml-1 btn-block btn-sm"
        (click)="modal.close(filesPromotion)">
        <i class="fas fa-cloud-upload-alt"></i>
      </button>

    </div>

  </div>

</ng-template>

<!-- Alerta para mostrar el detalle de la cotizacion-->
<ng-template #showDetailCotizationModal let-modal>
  <div class="bg-light rounded">
    <div class="row mt-3 text-normal">
      <div class="col-10 text-left">
        <p class="text-size-14 ml-4"><strong>Pedido Nro. {{cotizationsSelected.id}}</strong></p>
      </div>

      <div class="col-2">
        <i class="far fa-times-circle text-danger" (click)="modal.dismiss('Cross click')"></i>
      </div>
    </div>
    <hr class="mt-0">
    <div class="modal-body pt-0 text-minuscula">
      <div class="row text-size-14">
        <div class="col-lg-8">
          <span class="text-normal text-size-13">Cliente: </span>
          <span>{{cotizationsSelected.nombres_cliente}} {{cotizationsSelected.apellidos_cliente}}</span>
          <br>
          <span class="text-normal text-size-13">Correo: </span>
          <span>{{cotizationsSelected.email_cliente}}</span>
        </div>

        <div class="col-lg-4">
          <span class="text-normal text-size-13">CI/Ruc: </span>
          <span>{{cotizationsSelected.cliente_cedulaRuc}}</span>
          <br>
          <span class="text-normal text-size-13">Celular: </span>
          <span>{{cotizationsSelected.celular_cliente}}</span>
        </div>

      </div>

      <br>

      <div class="row">
        <div class="col-lg-12 table-responsive">
          <table class="table table-hover text-size-13">
            <thead class="thead-light">
              <tr class="text-center">
                <th class="text-left" scope="col">Producto</th>
                <th scope="col">Cant.</th>
                <th scope="col">P. Unitario</th>
                <th scope="col">P. Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let p of cotizationsSelected.productos" class="text-size-11 text-normal text-center">
                <td class="text-left">{{p.pro_nom}}</td>
                <td>{{p.itemcantidad}}</td>
                <td>{{p.itempreciobruto}}</td>
                <td>{{p.itemprecioxcantidadneto}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <hr>

      <div class="row text-size-14 ml-3 mr-3">
        <div class="col-lg-12 text-right">

          <p class="m-0">
            <span class="pr-3">Subtotal 12%:</span>
            <span>{{cotizationsSelected.tarifadocebruto}}</span>
          </p>
          <p class="m-0">
            <span class="pr-3">Subtotal 0%:</span>
            <span>{{cotizationsSelected.tarifacerobruto}}</span>
          </p>
          <p class="m-0">
            <span class="pr-3">Subtotal:</span>
            <span>{{cotizationsSelected.subtotalBruto}}</span>
          </p>
          <p class="m-0">
            <span class="pr-3">Iva:</span>
            <span>{{cotizationsSelected.ivaval}}</span>
          </p>
          <p class="bg-secondary text-light">
            <strong>
              <span class="pr-3"><strong>Total:</strong></span>
              <span>{{cotizationsSelected.totalCompra}}</span>
            </strong>
          </p>

        </div>
      </div>

    </div>
  </div>
</ng-template>

<!-- Alerta para configurar el producto -->
<ng-template #configurationProductModal let-modal>
  <div class="row pl-2 pr-2 pb-4 bg-light">
    <div class="col-12 mt-3">
      <div class="row">
        <div class="col-12 pt-2 pb-2 rounded border alert alert-success text-size-18">
          <span class="float-left"> {{productSelected.id_producto}} / {{productSelected.pro_nom}}</span>
          <span class="float-right"><i class="far fa-times-circle text-danger"
              (click)="modal.dismiss('Cross click')"></i></span>
        </div>
      </div>
    </div>

    <div class="col-lg-12 text-size-14">
      <div class="modal-body pt-0">
        <div class="row">
          <!-- Mas vendido, es promo, vista web -->
          <div class="col-lg-12">
            <table class="table text-center">
              <thead class="thead-light">
                <tr>
                  <th scope="col">
                    <input type="checkbox" [checked]="productSelected.checked_mas_vendido"
                      (change)="updateAtributesProduct($event, 'mas_vendido', productSelected)">
                    <span class="ml-1 mt-2">Más vendido</span>
                  </th>
                  <th scope="col">
                    <input type="checkbox" [checked]="productSelected.checked_es_promo"
                      (change)="updateAtributesProduct($event, 'es_promo', productSelected)">
                    <span class="ml-1 mt-2">Es promo</span>
                  </th>
                  <th scope="col">
                    <input type="checkbox" [checked]="productSelected.checked_vista_web"
                      (change)="updateAtributesProduct($event, 'vista_web', productSelected)">
                    <span class="ml-1 mt-2">Vista web</span>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <!-- Descripcion y video -->
          <div class="col-lg-12">
            <label class="d-block "><strong>Descripción</strong></label>
            <textarea name="textarea" class="form-control form-control-sm input-sm w-100" placeholder="Descripción"
              title="Descripción del producto" [(ngModel)]="productSelected.descripcion"></textarea>

            <label class="d-block mt-3"><strong>Url Video</strong></label>
            <input type="text" class="form-control form-control-sm input-sm" placeholder="Url video"
              placeholder="https://www.youtube.com" [(ngModel)]="productSelected.fotourl">
          </div>

          <!-- Boton guardar -->
          <div class="col-lg-12 text-center mt-3">
            <button type="button" class="btn btn-dark btn-sm text-center" type="submit"
              (click)="updateAtributesProduct(variables.referido, 'descripcion', productSelected)">
              <i class="fa fa-floppy-o text-size-11" aria-hidden="true"></i>
            </button>
            <hr>
          </div>

          <!-- Referidos -->
          <div class="col-lg-12 mt-3">
            <label class="d-block" *ngIf="productReferidos.length > 0"><strong>Productos Referidos</strong></label>
            <table class="table text-center">
              <thead class="">
                <tr>
                  <th *ngFor="let p of productReferidos">
                    <button type="button" class="btn btn-outline-dark btn-sm"
                      (click)="deleteProductReferido(p, productReferidos, productSelected)">
                      <span style="font-size: 11px;">{{p.nombre}}</span>
                      <i class="fa fa-times-circle text-danger ml-1" aria-hidden="true"></i>
                    </button>
                  </th>
                </tr>
              </thead>
            </table>

            <label class="d-block "><strong>Nuevo Referido</strong></label>
            <form>
              <div class="input-group input-group-sm mb-3">
                <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                  name="search" placeholder="Buscar producto nombre / código" [(ngModel)]="searchProd">
                <div class="input-group-prepend">
                  <button type="button" class="btn btn-success btn-sm btn-block"
                    (click)="searchProductForReferido(searchProd)" type="submit">
                    <i class="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </form>

            <div class="row form-group">
              <div class="col-lg-12 table-responsive" *ngIf="searchProductsReferidos.length > 0">
                <hr>
                <table class="table table-hover text-size-13">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Producto</th>
                      <th scope="col" class="text-center">Agregar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let p of searchProductsReferidos" class="text-size-11 text-normal"
                      (click)="addProductReferido(p, productSelected)">
                      <td>{{p.id_producto}} / {{p.nombre_producto}}</td>
                      <td class="text-center"><i class="fa fa-plus-circle text-success text-size-16"
                          aria-hidden="true"></i></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Alerta para configurar el subgrupo -->
<ng-template #configurationSubgrupoModal let-modal>
  <div class="row pl-2 pr-2 pb-4 bg-light">
    <div class="col-12 mt-3">
      <div class="row">
        <div class="col-12 pt-2 pb-2 rounded border alert alert-success text-size-18">
          <span class="float-left">{{groupSelected.nombre | uppercase}}</span>
          <span class="float-right"><i class="far fa-times-circle text-danger"
              (click)="modal.dismiss('Cross click')"></i></span>
        </div>
      </div>
    </div>

    <div class="col-lg-12 text-size-14">
      <div class="modal-body pt-0">
        <div class="row">
          <div class="col-lg-12 table-responsive">
            <div class="text-center">
              <label class="col-form-label"> <strong>Imagenes Subgrupos</strong></label>
              <p class="text-danger text-size-13">El tamaño recomendado de la imagen debe ser de 1024 * 1024 px</p>
            </div>

            <table class="table table-hover text-size-13">
              <thead>
                <tr class="text-center">
                  <th scope="col">id</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Imagen</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let s of groupSelected.subgroups" class="text-size-13 text-normal text-center">
                  <th scope="row" *ngIf="s.id_sub != 0">{{s.id_sub}}</th>
                  <td *ngIf="s.id_sub != 0">
                    {{s.nombre}}
                  </td>
                  <td (click)="ModalUploadFile(uploadFileModal, 'Subgroups', s)" *ngIf="s.id_sub != 0">
                    <img [src]="urlBase+s.img+'?alt=media'" class="mr-2"
                      style="max-width: 150px" *ngIf="s.img">
                    <!-- <img src="https://drive.google.com/uc?export=view&id={{s.img}}" class="mr-2"
                      style="max-width: 150px" *ngIf="s.img"> -->
                    <img  src="../../../../assets/img/imgDefault/sinProd.png"   class="mr-2"
                      style="max-width: 150px" *ngIf="!s.img">
                    <!-- <img src="https://drive.google.com/uc?export=view&id=1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2" class="mr-2"
                      style="max-width: 150px" *ngIf="!s.img"> -->
                    <i class="fas fa-edit ml-4" title="Editar imagen del Subgrupo"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Alerta para crear la guia de talla -->
<ng-template #createGuiaTallasModal let-modal>
  <div class="row pl-2 pr-2 pb-2 bg-light">
    <div class="col-12 mt-3">
      <div class="row">
        <div class="col-12 pt-2 pb-2 rounded border alert alert-success text-size-14">
          <span class="float-left">Crear guía de tallas para el grupo</span>
          <span class="float-right"><i class="far fa-times-circle text-danger"
              (click)="modal.dismiss('Cross click')"></i></span>
        </div>
      </div>
    </div>

    <div class="col-lg-12 text-size-12">
      <div class="form-group mt-2 mb-0">
        <span>Seleccione el Grupo</span>
        <select class="form-control form-control-sm text-size-10 mt-2"
          (change)="selectGroupGuiaTalla($event.target.value)">
          <option class="text-size-10" selected disabled>Seleccione</option>
          <option value="{{g.codigo}}" *ngFor="let g of groupsFree" class="text-size-10">{{g.nombre}}</option>
        </select>
        <br>
      </div>
    </div>

    <div class="col-lg-4 border rounded text-size-12" *ngIf="newGuiaTalla.guiaTallas.id_grupo">
      <p class="text-center text-size-13 pt-2 mb-0 border-bottom"><label><strong>Nueva Guia de talla</strong></label>
      </p>

      <div class="form-group mt-2">
        <label>Parte de medida <span class="text-danger text-size-20 w-100">*</span></label>
        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name="" id=""
          placeholder="Ejm: Cintura" [(ngModel)]="newGuiaTalla.guiaTallas.medida">
      </div>

      <div class="form-group">
        <label>Descripción</label>
        <textarea style="font-size: 13px;" class="form-control form-control-sm text-normal text-justify" rows="3"
          placeholder="Ejm: Mida en el punto más estrecho" [(ngModel)]="newGuiaTalla.guiaTallas.descripcion"></textarea>
      </div>

      <div class="form-group text-center">
        <button class="btn btn-outline-dark btn-sm" (click)="createGuiaTallas(newGuiaTalla.guiaTallas)">
          <i class="fa fa-floppy-o"></i>
        </button>
      </div>
    </div>

    <div class="col-lg-8">
      <div class="border rounded p-2" *ngIf="guiaTallas.rta == true">
        <p class="text-center text-size-13 mb-0 border-bottom"><label><strong>Listado de Guia de talla</strong></label>
        </p>
        <table class="table table-hover table-striped table-sm table-bordered text-size-12 mt-2">
          <thead class="bg-success text-light">
            <tr class="">
              <th scope="col">Medida</th>
              <th scope="col">Descripcion</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let g of guiaTallas.data" class="text-size-13 text-normal">
              <td><input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name=""
                  id="" [(ngModel)]="g.medida"></td>
              <td><input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name=""
                  id="" [(ngModel)]="g.descripcion"></td>
              <td class="text-center" (click)="modalCreateGuiaTallasDetalle(createGuiaTallasDetalleModal, g)"><i
                  class="fa fa-eye text-dark icn-talla" aria-hidden="true"></i></td>
              <td class="text-center" (click)="updateGuiaTallas(g)"><i class="fa fa-refresh text-success icn-talla"
                  aria-hidden="true"></i></td>
              <td class="text-center" (click)="ModaldeleteFile(deleteFileModal, g, 'talla_guia')"><i
                  class="fa fa-trash-o text-danger icn-talla" aria-hidden="true"></i></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center border rounded p-2" *ngIf="guiaTallas.rta == false">
        <img class="mt-2 w-75" src="https://drive.google.com/uc?export=view&id=1X1L4AvE7SN3O_gIucIkmsc47pMASDdS2">
      </div>
    </div>
  </div>
</ng-template>

<!-- Alerta para agregar el detalle de la guia -->
<ng-template #createGuiaTallasDetalleModal let-modal>
  <div class="row pl-2 pr-2 pb-2 bg-light">
    <div class="col-12 mt-3">
      <div class="row">
        <div class="col-12 pt-2 pb-2 rounded border alert alert-warning text-size-14">
          <span class="float-left">Adicione una nueva talla en la guía</span>
          <span class="float-right"><i class="far fa-times-circle text-danger"
              (click)="modal.dismiss('Cross click')"></i></span>
        </div>
      </div>
    </div>

    <div class="col-lg-12 text-center text-size-12 pl-5 pr-5">
      <p class="alert alert-success pt-1 pb-1">{{name_guia}}</p>
    </div>

    <div class="col-lg-6 text-minuscula text-size-14">
      <div class="form-group text-size-14">
        <label>Nombre de la talla <span class="text-danger text-size-20">*</span></label>
        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name="" id=""
          placeholder="Ejm: XS" [(ngModel)]="newGuiaTalla.guia_tallas_detalle.nombre">
      </div>
    </div>

    <div class="col-lg-5 text-minuscula text-size-14">
      <div class="form-group text-size-14">
        <label>Valor <span class="text-danger text-size-20">*</span></label>
        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name="" id=""
          placeholder="Ejm: 42 EUR - 32 USA" [(ngModel)]="newGuiaTalla.guia_tallas_detalle.detalle">
      </div>
    </div>

    <div class="col-lg-1 text-minuscula text-size-14">
      <div class="form-group text-size-14">
        <label style="visibility: hidden;">*</label>
        <button class="btn btn-outline-dark btn-sm form-control"
          (click)="createGuiaTallasDetalle(newGuiaTalla.guia_tallas_detalle)">
          <i class="fa fa-floppy-o"></i>
        </button>
      </div>
    </div>

    <div class="col-lg-12 table-responsive" *ngIf="guiaTallasDetalle.show_detail == true">
      <hr>
      <table class="table table-hover table-striped table-sm table-bordered text-size-13">
        <thead class="bg-success text-light">
          <tr class="">
            <th scope="col">Nombre</th>
            <th scope="col">Detalle</th>
            <th scope="col" class="text-center"></th>
            <th scope="col" class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let g of guiaTallasDetalle.detalle" class="text-size-13 text-normal">
            <td><input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name=""
                id="" [(ngModel)]="g.nombre"></td>
            <td><input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name=""
                id="" [(ngModel)]="g.detalle"></td>
            <td class="text-center" (click)="updateGuiaTallasDetalle(g)"><i class="fa fa-refresh text-success icn-talla"
                aria-hidden="true"></i></td>
            <td class="text-center" (click)="ModaldeleteFile(deleteFileModal, g, 'talla_guia_detalle')"><i
                class="fa fa-trash-o text-danger icn-talla" aria-hidden="true"></i></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<!-- Crear Botón Acceso -->
<ng-template #createBtnAcceso let-modal>

  <div class="row pt-3 text-minuscula-texto-negro text-size-14">
    <div class="col-9 text-center">
      <!-- <p>Seleccione la imagen</p> -->
    </div>
    <div class="col-2 float-left">
      <button type="button" class="close text-danger" aria-label="Close" (click)="closeModalBtn()" >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  
  <form [formGroup]="formCrearButton" >
    <div class="row modal-body text-minuscula-texto-negro">
      <div class="col-lg-12">
        <div class="form-group">
          <p>
            <i class="fas fa-signature mr-2 text-dark"></i>
            <label class="text-size-12 mb-3">Título <span class="text-danger">*</span></label>
            <input type="text" class="form-control form-control-sm" name="titulo" placeholder="Ingrese título del botón" 
              formControlName="titulo"
              >
          </p>
        </div>

        <div class="form-group">
          <p>
            <i class="far fa-newspaper mr-2 text-dark"></i>
            <label class="text-size-12 mb-3">URL</label>
            <input style="font-size: 13px;" class="form-control form-control-sm text-normal" rows="3" placeholder="Ingrese url al que desea redirigir" 
            formControlName="url">
          </p>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <p>
                  <i class="bi bi-palette mr-2 text-dark"></i>
                  <label class="text-size-12 mb-3">Color Botón <span class="text-danger">*</span></label>
                  <input type="color" style="font-size: 13px;width: 6em; " class="form-control"   formControlName="colorBoton">
                </p>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <p>
                  <i class="bi bi-palette-fill mr-2 text-dark"></i>
                  <label class="text-size-12 mb-3">Color Letra Botón</label>
                  <input type="color" style="font-size: 13px;width: 6em" class="form-control"  formControlName="colorLetra">
                </p>
              </div>
            </div>
          </div>
        </div>

        <br>

        <button  *ngIf="tipoBtn=='create'"  [disabled]="formCrearButton.invalid" type="button" ngbAutofocus class="btn btn-secondary ml-1 btn-block btn-sm"
        (click)="crearNuevoBoton(formCrearButton.value)"
          >
          Crear Botón
        </button>
        <button  *ngIf="tipoBtn!='create'" type="button" ngbAutofocus class="btn btn-secondary ml-1 btn-block btn-sm"
        (click)="editNuevoBoton(formCrearButton.value)"
          >
          Editar Botón
        </button>

      </div>

    </div>
</form>
</ng-template>

<ng-template #createService let-modal>

  <div class="row pt-3 text-minuscula-texto-negro text-size-14">
    <div class="col-9 text-center">
      <!-- <p>Seleccione la imagen</p> -->
    </div>
    <div class="col-2 float-left">
      <button type="button" class="close text-danger" aria-label="Close" (click)="closeModalServicio()" >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  
  <form [formGroup]="formCrearServicio" >
    <div class="row modal-body text-minuscula-texto-negro">
      <div class="col-lg-12">
        <div class="form-group">
          <p>
            <i class="fas fa-signature mr-2 text-dark"></i>
            <label class="text-size-12 mb-3">Nombre <span class="text-danger">*</span></label>
            <input type="text" class="form-control form-control-sm" name="titulo" placeholder="Ingrese nombre del servicio" 
              formControlName="nombre"
              >
          </p>
        </div>

        <div class="form-group">
          <p>
            <i class="far fa-newspaper mr-2 text-dark"></i>
            <label class="text-size-12 mb-3">Descripción</label>
            <textarea style="font-size: 13px;"  class="form-control form-control-sm text-normal" rows="3" placeholder="Ingrese descripción del servicio "
            formControlName="detalle">
            </textarea>
          </p>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <div class="form-group" *ngIf="!flagPicture " >
                <p>
                  <i class="bi bi-image  mr-2"></i>
                  <label class="text-size-12 mb-3">Imagen <span class="text-danger">*</span></label>    
                    <input type="file" style="font-size: 13px; " class="form-control" (change)="selectImgService($event)"   accept="image/*">
                </p>
              </div>
              <div class="form-group" *ngIf="flagPicture">
                <p>
                  <i class="bi bi-image  mr-2"></i>
                  <label class="text-size-12 mb-3">Imagen <span class="text-danger">*</span></label>    
                </p>
                <div class="" style="position:relative"  >
                  <img [src]="urlBase+element.imagen+'?alt=media'" alt="" style="object-fit: scale-down;width: 100%; " >
                  <span  class="rounded-1 p-1" type="button"  (click)="changePicture(false)"  style="position: absolute; top:0; right: 0; background-color: #f2f2f4  " >
                    Cambiar Imagen
                  </span>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <p>
                  <i class="bi bi-palette-fill mr-2 text-dark"></i>
                  <label class="text-size-12 mb-3">Color Letra Botón</label>
                  <input type="color" style="font-size: 13px;width: 6em" class="form-control"  formControlName="colorLetra">
                </p>
              </div>
            </div>
          </div>
        </div>

        <br>

        <button  *ngIf="tipoBtn=='create'"  [disabled]="formCrearServicio.invalid" type="button" ngbAutofocus class="btn btn-secondary ml-1 btn-block btn-sm"
        (click)="crearNuevoServicio(formCrearServicio.value)"
          >
          Crear Servicio
        </button>
        <button  *ngIf="tipoBtn!='create'" type="button" ngbAutofocus class="btn btn-secondary ml-1 btn-block btn-sm"
        (click)="editNuevoServicio(formCrearServicio.value)"
          >
          Editar Servicio
        </button>

      </div>

    </div>
</form>
</ng-template>

<app-scroll-to-top></app-scroll-to-top>
<ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true}" [template]="">
</ngx-loading>