<ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true}" [template]="">
</ngx-loading>

<section>
    <form class="header-search-form text-size-13 text-center bg-light rounded">
        <div class="row mt-2 mr-2 text-mayuscula-texto">
            <div class="col-12 text-right text-size-24">
                <!-- <i class="far fa-times-circle text-danger" (click)="dismissModal('close', productSelected)"></i> -->
                <i class="far fa-times-circle text-danger" (click)="closeModal()"></i>
            </div>
        </div>
        <div class="modal-body pt-0">
            <div class="row">
                <div class="col-xxl-8 col-xl-8 col-lg-8 col-12 ">
                    <div class="row">
                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-12 ">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-3">
                                            <div class=" rounded-2 p-2 div-img-min shadow-sm"
                                                style="border: 1px solid #f2f2f4;  " *ngIf="productSelected.imagen_uno"
                                                (click)="imageSelectedView(productSelected.imagen_uno, productSelected)">
                                                <img class="img-galery-description"
                                                    [src]="rutaUrl+productSelected.imagen_uno+'?alt=media'" alt="">
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class=" rounded-2 p-2 div-img-min shadow-md"
                                                style="border: 1px solid #f2f2f4;" *ngIf="productSelected.imagen_dos "
                                                (click)="imageSelectedView(productSelected.imagen_dos, productSelected)">
                                                <img class="img-galery-description"
                                                    [src]="rutaUrl+productSelected.imagen_dos+'?alt=media'" alt="">
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class=" rounded-2 p-2 div-img-min shadow-md "
                                                style="border: 1px solid #f2f2f4; " *ngIf="productSelected.imagen_tres"
                                                (click)="imageSelectedView(productSelected.imagen_tres, productSelected)">
                                                <img class="img-galery-description"
                                                    [src]="rutaUrl+productSelected.imagen_tres+'?alt=media'" alt="">
                                            </div>

                                        </div>
                                        <div class="col-3">
                                            <div class="   rounded-2 p-2 div-img-min  shadow-md"
                                                style="border: 1px solid #f2f2f4;" *ngIf="productSelected.imagen_cuatro"
                                                (click)="imageSelectedView(productSelected.imagen_cuatro, productSelected)">
                                                <img class="img-galery-description"
                                                    [src]="rutaUrl+productSelected.imagen_cuatro+'?alt=media'" alt="">
                                            </div>
                                            <!-- style="background-color: #f2f2f4;"  -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="p-5">
                                        <img *ngIf="productSelected.imagenPrincipal != '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'"class="img-detalle-productos w-100"
                                            [src]="rutaUrl+productSelected.imagenPrincipal+'?alt=media'" alt="">
                                        <img *ngIf="productSelected.imagenPrincipal == '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'"
                                            class="img-detalle-productos w-100"
                                            src=".././../../assets/img/imgDefault/sinProd.png" alt="">

                                            <!-- <img class="img-productos p-2 rounded-3 "  *ngIf="p.imagenPrincipal == '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'"
                                            src="../../../assets/img/imgDefault/sinProd.png" alt="" alt="" style="height: 58%;" >
                                            <img class="img-productos p-2 rounded-3 "  *ngIf="p.imagenPrincipal != '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'"  [src]="rutaUrl+p.imagenPrincipal+'?alt=media'" alt="" style="height: 58%;" > -->
                                    </div>
                                    <div class="col-6" *ngIf="productSelected.fotourl">
                                        <button class="btn_youtube" (click)="redirectoVideo(productSelected.fotourl )">
                                                <span class="btn-video-product text-minuscula-negro">
                                                    <i class="fab fa-youtube youtube text-size-32" aria-hidden="true"></i>
                                                    <p class="p-0 m-0 b-0"></p>
                                                    <span class="w-100 text-size-12 spn-desc"><strong>Visualizar Video</strong></span>
                                                </span>
                                                <div class="reacciones_youtube w-100">
                                                    <span class="text-center w-100">
                                                        <i class="fa fa-television" aria-hidden="true"></i>
                                                        Visualizar Video
                                                    </span> 
                                                </div>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-12 p-3 rounded-5 " style="background-color: #f2f2f4;">
                            <!-- Detalle del producto -->
                            <div class="w-100 text-left">
                                <p class="text-size-20 text-mayuscula-texto text-left">
                                    {{productSelected.nombre_producto}}</p>
                                <!-- <p class="text-size-15 text-dorado mt-3">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </p> -->
                                <div class="mt-2 " style="display: flex; justify-content: space-between; " >
                                    <p class="text-size-16 text-mayuscula-texto text-center"
                                        *ngIf="configurationVariables.show_price">
                                        <span class="text-secondary mr-2 text-subrayado-gray"
                                            *ngIf="productSelected.precioOferta > 0" style="font-size: 22px; font-weight: bold; " >
                                            $ {{productSelected.precioOferta | number: '1.2'}}
                                        </span>
                                        <span>
                                            $ {{productSelected.precioReal | number: '1.2'}}
                                        </span>
                                    </p>
                                    <span *ngIf="productSelected.precioOferta > 0"
                                        class=" translate-middle badge   rounded-3 mt-3 "
                                        style="background-color: white; color:gray; font-size: 14px;">
                                        5 <i class="bi bi-star-fill text-warning "></i>
                                    </span>
                                </div>

                                <p class="text-size-12 pt-1 pb-1 rounded border border-warning bg-white"
                                    *ngIf="productSelected.combo_venta_volumen">
                                    <span *ngFor="let pric of productSelected.precios">
                                        <strong *ngIf="pric.combo" class="text-center">
                                            {{pric.combo}}
                                            <br>
                                        </strong>
                                    </span>
                                </p>
                                <p class="text-minuscula-texto text-size-14 text-justify mt-3"
                                    *ngIf="configuracion.descripcion_producto">
                                    <span
                                        *ngIf="!productSelected.desc_product_detail">{{productSelected.descripcion}}</span>
                                    <span
                                        *ngIf="productSelected.desc_product_detail">{{productSelected.desc_product_detail}}
                                        <strong style="color: black;" class="tipo-cursor"
                                            (click)="viewMoreDetail(productSelected)"
                                            *ngIf="productSelected.show_more_detail"> <i class="fas fa-eye ml-1"></i>
                                            Ver más</strong>
                                        <strong style="color: black;" class="tipo-cursor"
                                            (click)="viewMoreDetail(productSelected)"
                                            *ngIf="!productSelected.show_more_detail"> <i
                                                class="fas fa-eye-slash ml-1"></i> Ver menos</strong>
                                    </span>
                                </p>
                                <div class="border rounded-4 text-size-13 p-3 " style="background-color: white;"
                                    *ngIf="configurationVariables.show_attributes_product">
                                    <p
                                        class="text-size-13 text-mayuscula-texto text-center alert alert-warning border rounded-2 pt-1 pb-1">
                                        INFORMACIÓN ADICIONAL
                                        <i class="fa fa-info-circle ml-2" aria-hidden="true"></i>
                                    </p>
                                    <table class="table table-striped table-sm"
                                        *ngIf="configurationVariables.show_attributes_product">
                                        <tbody>
                                            <tr *ngIf="configuracion.marca_producto">
                                                <td class="text-secondary text-left">MARCA</td>
                                                <td class="text-normal text-left" style="color: gray;" >
                                                    {{productSelected.marca_nombre}}
                                                </td>
                                                <td class="float-right text-secondary"><i class="fa fa-bookmark"></i>
                                                </td>
                                            </tr>
                                            <tr *ngIf="configuracion.peso_producto">
                                                <td class="text-secondary text-left">PESO / PIEZAS</td>
                                                <td class="text-normal text-left" style="color: gray;">
                                                   {{productSelected.peso}}
                                                </td>
                                                <td class="float-right text-secondary"><i class="fa fa-th-large"></i>
                                                </td>
                                            </tr>
                                            <tr *ngIf="configuracion.medida_producto">
                                                <td class="text-secondary text-left">MEDIDA</td>
                                                <td class="text-normal text-left" style="color: gray;">
                                                    {{productSelected.valor_medida}}
                                                        {{productSelected.unidad_medida}}</td>
                                                <td class="float-right text-secondary"><i class="fa fa-compress"></i>
                                                </td>
                                            </tr>
                                            <tr *ngIf="configuracion.ubicacion_producto">
                                                <td class="text-secondary text-left">UBICACION</td>
                                                <td class="text-normal text-left">
                                                   {{productSelected.ubicacion}}
                                                </td>
                                                <td class="float-right text-secondary"><i class="fa fa-map-marker"></i>
                                                </td>
                                            </tr>
                                            <tr *ngIf="configuracion.cantidadxbulto_producto">
                                                <td class="text-secondary text-left">CANTIDAD POR BULTO</td>
                                                <td class="text-normal text-left">
                                                    <strong>{{productSelected.cant_bulto}}</strong>
                                                </td>
                                                <td class="float-right text-secondary"><i class="fa fa-cube"></i></td>
                                            </tr>
                                            <tr *ngIf="configuracion.origen_producto">
                                                <td class="text-secondary text-left">ORIGEN</td>
                                                <td class="text-normal text-left">
                                                    <strong *ngIf="productSelected.origen == 0">N/A</strong>
                                                    <strong *ngIf="productSelected.origen == 1">NACIONAL</strong>
                                                    <strong *ngIf="productSelected.origen == 2">IMPORTADO</strong>
                                                </td>
                                                <td class="float-right text-secondary"><i class="fa fa-globe"></i></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <!-- <div (click)="viewVideoProduct(showVideoModal, productSelected)" *ngIf="productSelected.fotourl" class="border rounded">
                                            <i class="fa fa-youtube-play youtube text-size-40 btn-video-product" aria-hidden="true"></i>
                                            <p class="text-size-12 sin-espacios tipo-cursor"><strong class="alert pt-1 pb-1 txt-video-product">Ver Video</strong></p>
                                        </div> -->
                                </div>
                            </div>
                            <!-- Talla -->
                            <div class="w-100 text-center mt-3 mb-3" *ngIf="productSelected.tallas.length > 0">
                                <select
                                    class="form-select form-select-lg w-100 pt-1 pb-1 pl-2 text-minuscula-texto text-size-13"
                                    (change)="getSizeProduct($event.target.value, productSelected)">
                                    <option *ngFor="let t of productSelected.tallas" value="{{t.id_producto}}">
                                        {{t.talla}}
                                    </option>
                                </select>
                            </div>
                            <!-- Colores -->
                            <div class="w-100 text-center text-size-14" *ngIf="productSelected.colores.length > 0">
                                <p
                                    class="text-size-13 text-mayuscula-texto text-center alert alert-success border rounded pt-1 pb-1 mb-0">
                                    COLORES
                                    <i class="fa fa-adjust ml-2" aria-hidden="true"></i>
                                </p>
                                <table class="table table-borderless">
                                    <th *ngFor="let item of productSelected.colores;" scope="col" class="pt-2 pb-2">
                                        <span class="text-size-30" [ngStyle]="{'color':item.colorCodigo}"
                                            (click)="selectedColorProduct(item, productSelected)">
                                            <i class="fa fa-check-circle border-color" aria-hidden="true"
                                                *ngIf="item.talla_color_selected == true && item.colorCodigo"></i>
                                            <i class="fa fa-circle border-color" aria-hidden="true"
                                                *ngIf="item.talla_color_selected == false && item.colorCodigo"></i>
                                            <i class="fa fa-question-circle border-color" aria-hidden="true"
                                                *ngIf="!item.colorCodigo"></i>
                                        </span>
                                    </th>
                                </table>
                            </div>
                            <!-- Cantidad -->
                            <div class="w-100 text-center text-size-14 mt-2">
                                <p
                                    class="text-size-13 text-mayuscula-texto text-center alert alert-success border rounded-3 pt-1 pb-1 mb-2">
                                    CANTIDAD
                                    <i class="fa fa-sort ml-2" aria-hidden="true"></i>
                                </p>
                                <table class="table table-bordered" style="border-top-left-radius: 15px; border-bottom-left-radius: 15px; ">
                                    <tbody>
                                        <tr class="text-center text-normal  rounded-5 " >
                                            <th (click)="quantityProduct('quit', productSelected)"   ><i
                                                    class="fas fa-minus text-size-10 icn-qty"></i></th>
                                            <td>{{productSelected.quantity}}</td>
                                            <td (click)="quantityProduct('add', productSelected)"><i
                                                    class="fas fa-plus text-size-10 icn-qty"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- Guarniciones -->
                            <div class="w-100 text-center text-size-14" *ngIf="productSelected.guarnicion">
                                <p
                                    class="text-size-13 text-mayuscula-texto text-center alert alert-info pt-1 pb-1 mb-2">
                                    GUARNICIONES
                                    <i class="fas fa-utensils ml-2 text-dark"></i>
                                </p>
                                <div class="alert alert-light text-size-13 text-left pt-0 pb-0" role="alert"
                                    *ngFor="let g of configurationVariables.guarnition">
                                    <input type="checkbox" class="form-check-input" value="{{g.guar_id}}"
                                        name="{{g.guar_name}}" [(ngModel)]="g.checked"
                                        (change)="addGuarnition($event, g, productSelected)">
                                    <label class="form-check-label ml-2">{{g.guar_name}}</label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- <div class=" col-xxl-4 col-xl-4 col-lg-4 text-center w-100">
                    <div class="row ml-1 p-2 border rounded">
                        <div class="col-3 w-100 border rounded p-2 div-img-min" *ngIf="productSelected.imagen_uno"
                            (click)="imageSelectedView(productSelected.imagen_uno, productSelected)">
                            <img class="img-galery-description"
                            [src]="rutaUrl+productSelected.imagen_uno+'?alt=media'"
                                alt="">
                        </div>
                        <div class="col-3 w-100 border rounded p-2  div-img-min" *ngIf="productSelected.imagen_dos"
                            (click)="imageSelectedView(productSelected.imagen_dos, productSelected)">
                            <img class="img-galery-description"
                            [src]="rutaUrl+productSelected.imagen_dos+'?alt=media'" alt="">
                        </div>
                        <div class="col-3 w-100 border rounded p-2 div-img-min" *ngIf="productSelected.imagen_tres"
                            (click)="imageSelectedView(productSelected.imagen_tres, productSelected)">
                            <img class="img-galery-description"
                            [src]="rutaUrl+productSelected.imagen_tres+'?alt=media'" alt="">
                        </div>
                        <div class="col-3 w-100 border rounded p-2 div-img-min" *ngIf="productSelected.imagen_cuatro"
                            (click)="imageSelectedView(productSelected.imagen_cuatro, productSelected)">
                            <img class="img-galery-description"
                            [src]="rutaUrl+productSelected.imagen_cuatro+'?alt=media'"
                                alt="">
                        </div>
                    </div>
                    <div class="row ml-1 mt-2 p-2 border rounded">
                        <div class="col-lg-12 w-100 ">                        
                            <img *ngIf="productSelected.imagenPrincipal" class="img-detalle-productos w-100" [src]="rutaUrl+productSelected.imagenPrincipal+'?alt=media'" alt="">
                            <img *ngIf="!productSelected.imagenPrincipal" class="img-detalle-productos w-100" src=".././../../assets/img/imgDefault/sinProd.png" alt="">
                        </div>
                    </div>
                    <div class="row ml-1 mt-3 mb-3">
                        <div class="col-6" *ngIf="productSelected.fotourl">
                            <button class="btn_youtube" (click)="redirectoVideo(productSelected.fotourl )">
                                    <span class="btn-video-product text-minuscula-negro">
                                        <i class="fab fa-youtube youtube text-size-32" aria-hidden="true"></i>
                                        <p class="p-0 m-0 b-0"></p>
                                        <span class="w-100 text-size-12 spn-desc"><strong>Visualizar Video</strong></span>
                                    </span>
                                    <div class="reacciones_youtube w-100">
                                        <span class="text-center w-100">
                                            <i class="fa fa-television" aria-hidden="true"></i>
                                            Visualizar Video
                                        </span> 
                                    </div>
                            </button>
                        </div>

                        <div class="col-6" *ngIf="sizeGuide.rta == true">
                            <button class="btn_tallas" (click)="ViewSizeGuide(showSizeGuideModal)">
                                    <span class="btn-tallas-product text-minuscula-negro">
                                        <i class="fas fa-tshirt text-size-32"></i>
                                        <p class="p-0 m-0 b-0"></p>
                                        <span class="float-left w-100 text-size-12 spn-desc"><strong>Guía de tallas</strong></span>
                                    </span>
                                    <div class="reacciones_tallas w-100">
                                        <span class="text-center w-100">
                                            <i class="fas fa-atlas" aria-hidden="true"></i>
                                            Guía de tallas
                                        </span> 
                                    </div>
                            </button>
                        </div>
                    </div>
                </div> -->

                <!-- Descipcion, tallas, colores, cantidad y guarniciones -->
                <!-- <div class=" col-xxl-4 col-xl-4 col-lg-4">
                    <div class="w-100 text-center">
                        <p class="text-size-20 text-mayuscula-texto text-center">{{productSelected.nombre_producto}}</p>
                        <p class="text-size-15 text-dorado mt-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </p>
                        <p class="text-size-16 text-mayuscula-texto text-center"
                            *ngIf="configurationVariables.show_price">
                            <span class="text-secondary mr-2 text-subrayado-gray"
                                *ngIf="productSelected.precioOferta > 0">
                                $ {{productSelected.precioOferta | number: '1.2'}}
                            </span>
                            <span>
                                $ {{productSelected.precioReal | number: '1.2'}}
                            </span>
                        </p>
                        <p class="text-size-12 pt-1 pb-1 rounded border border-warning bg-white"
                            *ngIf="productSelected.combo_venta_volumen">
                            <span *ngFor="let pric of productSelected.precios">
                                <strong *ngIf="pric.combo" class="text-center">
                                    {{pric.combo}}
                                    <br>
                                </strong>
                            </span>
                        </p>
                        <p class="text-minuscula-texto text-size-14 text-justify mt-3" *ngIf="configuracion.descripcion_producto">
                            <span *ngIf="!productSelected.desc_product_detail">{{productSelected.descripcion}}</span>
                            <span *ngIf="productSelected.desc_product_detail">{{productSelected.desc_product_detail}} 
                                <strong style="color: black;" class="tipo-cursor" (click)="viewMoreDetail(productSelected)" *ngIf="productSelected.show_more_detail"> <i class="fas fa-eye ml-1"></i> Ver más</strong>
                                <strong style="color: black;" class="tipo-cursor" (click)="viewMoreDetail(productSelected)" *ngIf="!productSelected.show_more_detail"> <i class="fas fa-eye-slash ml-1"></i> Ver menos</strong>
                            </span>
                        </p>
                        <div class="border rounded text-size-13 pt-3 pl-3 pr-3 pb-2" *ngIf="configurationVariables.show_attributes_product">
                            <p class="text-size-13 text-mayuscula-texto text-center alert alert-warning border rounded pt-1 pb-1">
                                INFORMACION ADICIONAL
                                <i class="fa fa-info-circle ml-2" aria-hidden="true"></i>
                            </p>
                            <table class="table table-striped table-sm" *ngIf="configurationVariables.show_attributes_product">
                                <tbody>
                                    <tr *ngIf="configuracion.marca_producto">
                                        <td class="text-secondary text-left">MARCA</td>
                                        <td class="text-normal text-left">
                                            <strong>{{productSelected.marca_nombre}}</strong>
                                        </td>
                                        <td class="float-right text-secondary"><i class="fa fa-bookmark"></i></td>
                                    </tr>
                                    <tr *ngIf="configuracion.peso_producto">
                                        <td class="text-secondary text-left">PESO / PIEZAS</td>
                                        <td class="text-normal text-left"><strong>{{productSelected.peso}}</strong></td>
                                        <td class="float-right text-secondary"><i class="fa fa-th-large"></i></td>
                                    </tr>
                                    <tr *ngIf="configuracion.medida_producto">
                                        <td class="text-secondary text-left">MEDIDA</td>
                                        <td class="text-normal text-left"><strong>{{productSelected.valor_medida}}
                                                {{productSelected.unidad_medida}}</strong></td>
                                        <td class="float-right text-secondary"><i class="fa fa-compress"></i></td>
                                    </tr>
                                    <tr *ngIf="configuracion.ubicacion_producto">
                                        <td class="text-secondary text-left">UBICACION</td>
                                        <td class="text-normal text-left"><strong>{{productSelected.ubicacion}}</strong>
                                        </td>
                                        <td class="float-right text-secondary"><i class="fa fa-map-marker"></i></td>
                                    </tr>
                                    <tr *ngIf="configuracion.cantidadxbulto_producto">
                                        <td class="text-secondary text-left">CANTIDAD POR BULTO</td>
                                        <td class="text-normal text-left">
                                            <strong>{{productSelected.cant_bulto}}</strong>
                                        </td>
                                        <td class="float-right text-secondary"><i class="fa fa-cube"></i></td>
                                    </tr>
                                    <tr *ngIf="configuracion.origen_producto">
                                        <td class="text-secondary text-left">ORIGEN</td>
                                        <td class="text-normal text-left">
                                            <strong *ngIf="productSelected.origen == 0">N/A</strong>
                                            <strong *ngIf="productSelected.origen == 1">NACIONAL</strong>
                                            <strong *ngIf="productSelected.origen == 2">IMPORTADO</strong>
                                        </td>
                                        <td class="float-right text-secondary"><i class="fa fa-globe"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="w-100 text-center mt-3 mb-3" *ngIf="productSelected.tallas.length > 0">
                        <select class="form-select form-select-lg w-100 pt-1 pb-1 pl-2 text-minuscula-texto text-size-13" (change)="getSizeProduct($event.target.value, productSelected)">
                            <option *ngFor="let t of productSelected.tallas" value="{{t.id_producto}}">{{t.talla}}
                            </option>
                        </select>
                    </div>
                    <div class="w-100 text-center text-size-14" *ngIf="productSelected.colores.length > 0">
                        <p class="text-size-13 text-mayuscula-texto text-center alert alert-success border rounded pt-1 pb-1 mb-0">
                            COLORES
                            <i class="fa fa-adjust ml-2" aria-hidden="true"></i>
                        </p>
                        <table class="table table-borderless">
                            <th *ngFor="let item of productSelected.colores;" scope="col" class="pt-2 pb-2">
                                <span class="text-size-30" [ngStyle]="{'color':item.colorCodigo}" (click)="selectedColorProduct(item, productSelected)">
                                    <i class="fa fa-check-circle border-color" aria-hidden="true" *ngIf="item.talla_color_selected == true && item.colorCodigo"></i>
                                    <i class="fa fa-circle border-color" aria-hidden="true" *ngIf="item.talla_color_selected == false && item.colorCodigo"></i>
                                    <i class="fa fa-question-circle border-color" aria-hidden="true" *ngIf="!item.colorCodigo"></i>
                                </span>
                            </th>
                        </table>
                    </div>
                    <div class="w-100 text-center text-size-14">
                        <p class="text-size-13 text-mayuscula-texto text-center alert alert-success border rounded pt-1 pb-1 mb-2">
                            CANTIDAD
                            <i class="fa fa-sort ml-2" aria-hidden="true"></i>
                        </p>
                        <table class="table table-bordered">
                            <tbody>
                                <tr class="text-center text-normal">
                                    <th (click)="quantityProduct('quit', productSelected)"><i class="fas fa-minus text-size-10 icn-qty"></i></th>
                                    <td>{{productSelected.quantity}}</td>
                                    <td (click)="quantityProduct('add', productSelected)"><i class="fas fa-plus text-size-10 icn-qty"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="w-100 text-center text-size-14" *ngIf="productSelected.guarnicion">
                        <p class="text-size-13 text-mayuscula-texto text-center alert alert-info pt-1 pb-1 mb-2">
                            GUARNICIONES 
                            <i class="fas fa-utensils ml-2 text-dark"></i>
                        </p>
                        <div class="alert alert-light text-size-13 text-left pt-0 pb-0" role="alert" *ngFor="let g of configurationVariables.guarnition">
                            <input type="checkbox" class="form-check-input" value="{{g.guar_id}}" name="{{g.guar_name}}" [(ngModel)]="g.checked" (change)="addGuarnition($event, g, productSelected)">
                            <label class="form-check-label ml-2">{{g.guar_name}}</label>
                        </div>
                    </div>
                </div> -->

                <div class="col-xxl-4 col-xl-4 col-lg-4 col-12 text-center">
                    <!-- Spinner -->
                    <div class="row" style="position: relative; top: 50%;" *ngIf="configurationVariables.spinner">
                        <div class="col-lg-12 pt-3 pl-5 pr-5">
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-secondary"
                                    role="progressbar" style="width: 100%" aria-valuenow="75" aria-valuemin="0"
                                    aria-valuemax="100"></div>
                            </div>
                            <p class="text-minuscula-texto text-size-12 pt-3 text-center">Espere un momento ...</p>
                        </div>
                    </div>
                    <!-- Productos sugeridos -->
                    <div class="row pl-2">
                        <div class="col-lg-12 w-100 text-mayuscula-texto text-size-13 text-center"
                            *ngIf="configurationVariables.referidos.length > 0 && configurationVariables.exists_referidos == true">
                            <p class="pt-2 pb-2 pl-5 pr-5 alert alert-primary border rounded">PRODUCTOS SUGERIDOS<i
                                    class="fa fa-tags ml-2" aria-hidden="true"></i></p>
                        </div>

                        <div class="col-lg-12 w-100 text-mayuscula-texto text-size-13 text-center" 
                            *ngIf="configurationVariables.referidos.length > 0 && configurationVariables.exists_referidos == false">
                            <p class="pt-2 pb-2 pl-5 pr-5 alert   rounded-5 text-secondary fw-semibold" style="background-color: #f2f2f4;" >TENDENCIAS<i
                                    class="fa fa-diamond ml-2" aria-hidden="true"></i></p>
                        </div>

                        <div class="col-xxl-12 col-xl-12 col-lg-6 col-12 mt-2 mb-3"
                            *ngFor="let p of configurationVariables.referidos; let primero = first; let ultimo = last;">
                            <div class="card  pt-1 bg-white m-2 rounded-4" style="border:1px solid #f2f2f4" >
                                <div class="row   " >
                                    <div class="col-4 divSelect ">
                                        <img class="img-productos p-2 rounded-3 "  *ngIf="p.imagenPrincipal == '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'"
                                        src="../../../assets/img/imgDefault/sinProd.png" alt="" alt="" style="height: 58%;" >
                                        <img class="img-productos p-2 rounded-3 "  *ngIf="p.imagenPrincipal != '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'"  [src]="rutaUrl+p.imagenPrincipal+'?alt=media'" alt="" style="height: 58%;" >
                                    </div>
                                    <div class="col-8" >
                                        <div>
                                            <p style="font-size: 12px; text-align: left; " >{{p.pro_nom}}</p>
                                            
                                        </div>
                                        <div class="mt-2 " style="display: flex; justify-content: space-between; " >
                                            <p class="text-size-16 text-mayuscula-texto text-center"
                                                *ngIf="configurationVariables.show_price">
                                                <span class="text-secondary mr-2 text-subrayado-gray"
                                                    *ngIf="p.precioOferta > 0" style="font-size: 16px; font-weight: bold; " >
                                                    $ {{p.precioOferta | number: '1.1-2'}}
                                                </span>
                                                <span>
                                                    $ {{p.precioReal | number: '1.2'}}
                                                </span>
                                            </p>
                                            <span *ngIf="p.precioOferta > 0"
                                                class=" translate-middle badge   rounded-3 mt-3 "
                                                style="background-color: #f2f2f4; color:gray; font-size: 14px;">
                                                5 <i class="bi bi-star-fill text-warning "></i>
                                            </span>
                                        </div>
                                        <div class="d-flex" style="justify-content: space-around;" >
                                            <button  (click)="verifyLoginClient(p, 'referido')" 
                                                     class="btn btn-sm rounded-3  " style="background-color: rgb(255, 255, 214);"
                                            >
                                                <i class="fas fa-cart-plus text-warning" ></i>
                                            </button>
                                            <button class="btn btn-sm rounded-3  "  style="background-color: rgb(229, 255, 223);" (click)="selectProductReferido(p)">
                                                <i class="fas fa-eye text-success"></i>
                                            </button>
                                        </div>
    
                                    </div>
                                </div>
                            </div>

                            <!-- <div
                                class="card shadow pt-2 bg-white rounded div-product animate__animated animate__zoomIn">
                                <div class="w-100 text-center" (click)="selectProductReferido(p)">
                                    <img class="img-productos" [src]="rutaUrl+p.imagenPrincipal+'?alt=media'" alt="">
                                    <p class="text-size-11 mt-2 mb-1 text-dorado">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </p>
                                </div>

                                <div class="card-body text-minuscula pt-1 height-125">
                                    <p class="text-size-12 text-center sin-espacios"
                                        *ngIf="configurationVariables.show_price">
                                        <span class="text-secondary text-subrayado-gray" *ngIf="p.precioOferta > 0">$
                                            {{p.precioOferta | number: '1.2'}} </span>
                                    </p>
                                    <p class="text-size-12 text-center sin-espacios"
                                        *ngIf="configurationVariables.show_price">
                                        <span> $ {{p.precioReal | number: '1.2'}} </span>
                                    </p>
                                    <hr>
                                    <p class="text-size-12 text-center pl-0 pr-0 sin-espacios">{{p.nombre_producto}}</p>
                                </div>

                                <hr>
                                <div class="row text-center pb-1 text-size-12">
                                    <div class="col-4 text-right div-icn-prod"
                                        (click)="verifyLoginClient(p, 'referido')">
                                        <i class="fas fa-cart-plus"></i>
                                    </div>

                                    <div class="col-4 text-center div-icn-prod" (click)="selectProductReferido(p)">
                                        <i class="fas fa-eye"></i>
                                    </div>

                                    <div class="col-4 text-left div-icn-prod" (click)="sharedFacebook(p)">
                                        <i class="fas fa-share-alt"></i>
                                    </div>
                                </div>
                                <br>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 mt-3">
                    <hr>
                    <div class="pl-5 pr-5 text-center">
                        <button type="button" class="btn btn-md btn-block mt-4 rounded-3"
                            (click)="dismissModal('acept', productSelected)" id="btn-add-cart">
                            <span class="text-size-13 mr-2 text-mayuscula-texto text-white ">Agregar al carrito</span>
                            <i class="fab fa-opencart  text-white"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>

<!-- Alerta para visualizar video del producto -->
<ng-template #showVideoModal let-modal>
    <div class="row pl-2 pr-2 pb-2 bg-light">
        <div class="col-12 mt-3">
            <div class="row">
                <div class="col-12 pt-2 pb-2 rounded border alert text-size-14" id="div-video">
                    <span class="float-left"><i class="fas fa-play-circle"></i></span>
                    <span class="float-right"><i class="far fa-times-circle text-size-16"
                            (click)="modal.dismiss('Cross click')"></i></span>
                </div>
            </div>
        </div>

        <div class="col-lg-12">
            <youtube-player [videoId]="productSelected.url_video_youtube" width="100%" height="400px"></youtube-player>
        </div>
    </div>
</ng-template>

<!-- Alerta para visualizar los referido del producto -->
<ng-template #showReferidosModal let-modal>
    <div class="row mt-2 mr-2">
        <div class="col-12 text-right text-mayuscula-texto">
            <span class="pl-5 pr-5 float-left alert alert-info pt-2 pb-2">PRODUCTOS SUGERIDOS</span>
            <i class="far fa-times-circle text-danger text-size-24 float-right" (click)="modal.dismiss('')"></i>
        </div>
    </div>

    <div class="row pl-2">
        <div class="col-lg-3 mt-2 mb-3" *ngFor="let p of configurationVariables.referidos">
            <div class="card shadow pt-2 bg-white rounded">
                <!-- Imagen y estrellas -->
                <div class="w-100 text-center">
                    <img class="img-productos" [src]="rutaUrl+p.imagenPrincipal+'?alt=media'" alt="">
                    <!-- <img class="img-productos" src="{{rutaUrl}}{{p.imagenPrincipal}}"
                        alt=""> -->
                    <p class="text-size-11 mt-2 mb-1 text-dorado">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                    </p>
                </div>
                <!-- Precio y nombre -->
                <div class="card-body text-minuscula pt-1 height-125">
                    <p class="text-size-14 text-center sin-espacios" *ngIf="configurationVariables.show_price">
                        <span class="text-secondary text-subrayado-gray" *ngIf="p.precioOferta > 0">$ {{p.precioOferta |
                            number: '1.2'}} </span>
                    </p>
                    <p class="text-size-14 text-center sin-espacios" *ngIf="configurationVariables.show_price">
                        <span> $ {{p.precioReal | number: '1.2'}} </span>
                    </p>
                    <hr>
                    <p class="text-size-12 text-center pl-0 pr-0 sin-espacios">{{p.nombre_producto}}</p>
                    <p class="text-size-11 sin-espacios mt-1 pt-1 pb-1 bg-light rounded border border-warning"
                        *ngIf="p.combo_venta_volumen">
                        <span *ngFor="let pric of p.precios">
                            <strong *ngIf="pric.combo" class="text-center">
                                {{pric.combo}}
                                <br>
                            </strong>
                        </span>
                    </p>
                </div>
                <!-- Botones -->
                <hr>
                <div class="row text-center ">
                    <div class="col-4 text-right div-icn-prod" (click)="verifyLoginClient(p, 'referido')">
                        <i class="fas fa-cart-plus"></i>
                    </div>

                    <div class="col-4 text-center div-icn-prod" (click)="modal.dismiss(p)">
                        <i class="fas fa-eye"></i>
                    </div>

                    <div class="col-4 text-left div-icn-prod" (click)="sharedFacebook(p)">
                        <i class="fas fa-share-alt"></i>
                    </div>
                </div>
                <br>
            </div>
        </div>
    </div>

    <div class="row border rounded pt-2 pl-5 pr-5"
        style="position: fixed; top:50%; left: 45%; background-color: black; opacity: 0.7;" *ngIf="loadingAux">
        <div class="col-lg-12 text-mayuscula-texto">
            <p class="text-minuscula pt-2 pr-3 ml-2 text-size-13 float-right text-light">Espere un momento ..</p>
            <div class="spinner-border text-info float-right" role="status"></div>
        </div>
    </div>
</ng-template>

<!-- Alerta para visualizar la guia de las tallas -->
<ng-template #showSizeGuideModal let-modal>
    <div class="row pl-2 pr-2 pb-2 bg-light">
        <div class="col-12 mt-3">
            <div class="row">
                <div class="col-12 pt-2 pb-2 rounded border alert text-size-14" id="head-talla">
                    <span class="float-left">GUIA DE TALLAS</span>
                    <span class="float-right"><i class="far fa-times-circle text-size-16"
                            (click)="modal.dismiss('Cross click')"></i></span>
                </div>
            </div>
        </div>

        <div class="col-lg-12 mt-2 mb-3 table-responsive">
            <table class="table table-hover table-bordered table-striped table-sm  text-size-13">
                <thead class="alert alert-success">
                    <tr class="text-left">
                        <th>Medida</th>
                        <th>Descripcion</th>
                        <td>Tallas</td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-left" *ngFor="let gt of sizeGuide.data">
                        <td>{{gt.medida}}</td>
                        <td>{{gt.descripcion}}</td>
                        <td>
                    <tr class="mb-0 bb-0">
                        <th *ngFor="let gtd of gt.detalle" class="border-success bg-light text-center p-0 text-size-12">
                            <span
                                class="w-100 float-left border-bottom border-success"><strong>{{gtd.nombre}}</strong></span>
                            <span>{{gtd.detalle}}</span>
                        </th>
                    </tr>
                    </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>